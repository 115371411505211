"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const valid_ui_framework_1 = require("valid-ui-framework");
const wf_vic_settings_1 = require("wf-vic-settings");
const redux_1 = require("redux");
const valid_ui_components_1 = require("valid-ui-components");
const UserLocation = ({ userLocation, getMyLoc }) => {
    const [loading, setLoading] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        getMyLoc()
            .then(() => {
            setLoading(false);
        })
            .catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }, [getMyLoc]);
    if (loading) {
        return (react_1.default.createElement(valid_ui_components_1.WfLoadingSpinner, null));
    }
    if (userLocation && userLocation.name) {
        return (react_1.default.createElement("div", null,
            react_1.default.createElement("span", { className: "home-location-text" }, 'Your Location: ' + userLocation.name)));
    }
    return (react_1.default.createElement("div", { className: "home-location-text text-center" }, "Contact your administrator to have your location assigned."));
};
const mapStateToProps = (state) => {
    return {
        userLocation: state.settings.userLocation
    };
};
const mapDispatchToProps = (dispatch) => {
    return (0, redux_1.bindActionCreators)({
        getMyLoc: wf_vic_settings_1.getMyLocation
    }, dispatch);
};
exports.default = (0, valid_ui_framework_1.frameworkConnect)(mapStateToProps, mapDispatchToProps)(UserLocation);
