"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEARDOWN_MERGE_IDENTITY_WORKFLOW = exports.MERGE_IDENTITIES_FAILURE = exports.MERGE_IDENTITIES_SUCCESS = exports.MERGE_IDENTITIES_REQUEST = exports.GET_IDENTITY_TWO_FAILURE = exports.GET_IDENTITY_TWO_SUCCESS = exports.GET_IDENTITY_TWO_REQUEST = exports.GET_IDENTITY_ONE_FAILURE = exports.GET_IDENTITY_ONE_SUCCESS = exports.GET_IDENTITY_ONE_REQUEST = void 0;
exports.GET_IDENTITY_ONE_REQUEST = 'GET_IDENTITY_ONE_REQUEST';
exports.GET_IDENTITY_ONE_SUCCESS = 'GET_IDENTITY_ONE_SUCCESS';
exports.GET_IDENTITY_ONE_FAILURE = 'GET_IDENTITY_ONE_FAILURE';
exports.GET_IDENTITY_TWO_REQUEST = 'GET_IDENTITY_TWO_REQUEST';
exports.GET_IDENTITY_TWO_SUCCESS = 'GET_IDENTITY_TWO_SUCCESS';
exports.GET_IDENTITY_TWO_FAILURE = 'GET_IDENTITY_TWO_FAILURE';
exports.MERGE_IDENTITIES_REQUEST = 'MERGE_IDENTITIES_REQUEST';
exports.MERGE_IDENTITIES_SUCCESS = 'MERGE_IDENTITIES_SUCCESS';
exports.MERGE_IDENTITIES_FAILURE = 'MERGE_IDENTITIES_FAILURE';
exports.TEARDOWN_MERGE_IDENTITY_WORKFLOW = 'TEARDOWN_MERGE_IDENTITY_WORKFLOW';
