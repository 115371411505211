"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEARDOWN_CM_WORKFLOW = exports.UPDATE_CR_CM_SUCCESS = exports.PERSON_SUBMIT_RELEASE_CM_SUCCESS = exports.PRINT_RELEASE_CM_SUCCESS = exports.UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_FAILURE = exports.UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_SUCCESS = exports.UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_REQUEST = exports.CLONE_CM_CREDENTIAL_FAILURE = exports.CLONE_CM_CREDENTIAL_SUCCESS = exports.CLONE_CM_CREDENTIAL_REQUEST = exports.CLONE_CM_IDENTITY_FAILURE = exports.CLONE_CM_IDENTITY_SUCCESS = exports.CLONE_CM_IDENTITY_REQUEST = exports.GET_CM_IDENTITY_FAILURE = exports.GET_CM_IDENTITY_SUCCESS = exports.GET_CM_IDENTITY_REQUEST = void 0;
exports.GET_CM_IDENTITY_REQUEST = 'GET_CM_IDENTITY_REQUEST';
exports.GET_CM_IDENTITY_SUCCESS = 'GET_CM_IDENTITY_SUCCESS';
exports.GET_CM_IDENTITY_FAILURE = 'GET_CM_IDENTITY_FAILURE';
exports.CLONE_CM_IDENTITY_REQUEST = 'CLONE_CM_IDENTITY_REQUEST';
exports.CLONE_CM_IDENTITY_SUCCESS = 'CLONE_CM_IDENTITY_SUCCESS';
exports.CLONE_CM_IDENTITY_FAILURE = 'CLONE_CM_IDENTITY_FAILURE';
exports.CLONE_CM_CREDENTIAL_REQUEST = 'CLONE_CM_CREDENTIAL_REQUEST';
exports.CLONE_CM_CREDENTIAL_SUCCESS = 'CLONE_CM_CREDENTIAL_SUCCESS';
exports.CLONE_CM_CREDENTIAL_FAILURE = 'CLONE_CM_CREDENTIAL_FAILURE';
exports.UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_REQUEST = 'UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_REQUEST';
exports.UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_SUCCESS = 'UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_SUCCESS';
exports.UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_FAILURE = 'UPDATE_CM_CREDENTIAL_REQUEST_WITH_TEMPDOC_FAILURE';
exports.PRINT_RELEASE_CM_SUCCESS = 'PRINT_RELEASE_CM_SUCCESS';
exports.PERSON_SUBMIT_RELEASE_CM_SUCCESS = 'PERSON_SUBMIT_RELEASE_CM_SUCCESS';
exports.UPDATE_CR_CM_SUCCESS = 'UPDATE_CR_CM_SUCCESS';
exports.TEARDOWN_CM_WORKFLOW = 'TEARDOWN_CM_WORKFLOW';
