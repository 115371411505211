"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const copyCredentialRequest = (cr) => {
    const newCR = Object.assign({}, cr);
    delete newCR.fields.lastfactoryupdate;
    delete newCR.fields.modifieddate;
    delete newCR.fields.modifiedby;
    // was accidentally putting in a misspelled version of document discriminator on the records.
    // remove it from the fields so as not to goof things in the future.
    if (newCR.fields.documentdiscrimintor !== undefined) {
        delete newCR.fields.documentdiscrimintor;
    }
    newCR.credentialid = null;
    newCR.fields.documentdiscriminator = null;
    newCR.fields.releasetoprint = false;
    newCR.comments = [];
    newCR.workItems = [];
    newCR.renders = [];
    newCR.addresses = [];
    newCR.endorsements.forEach((end) => {
        delete end.createdBy;
        delete end.createdDate;
        delete end.deletedBy;
        delete end.deletedDate;
        delete end.id;
        delete end.modifiedBy;
        delete end.modifiedDate;
    });
    const restrictions = newCR.restrictions.filter((restrict) => {
        return restrict.name !== '8 - None';
    });
    restrictions.forEach((res) => {
        delete res.createdBy;
        delete res.createdDate;
        delete res.deletedBy;
        delete res.deletedDate;
        delete res.id;
        delete res.modifiedBy;
        delete res.modifiedDate;
    });
    newCR.restrictions = restrictions;
    return newCR;
};
exports.default = copyCredentialRequest;
