"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCredentialRequestForm = exports.validatePersonForm = exports.validateMultistepEnrollmentForm = void 0;
const verifySSN = (errors, ssn) => {
    if (ssn && ssn.length && ssn !== '********') {
        const ssnTest = RegExp(/^(\d{9})$/, 'g');
        if (ssn.length < 9 || ssn.length > 9) {
            errors.ssn = 'Invalid SSN Length';
        }
        else if (!ssnTest.test(ssn)) {
            errors.ssn = 'Invalid SSN Format';
        }
    }
    return errors;
};
const validateMultistepEnrollmentForm = (values) => {
    const errors = {
        person: values.person && Object.assign({}, (0, exports.validatePersonForm)(values.person, values.credentialRequest)),
        credentialRequest: values.credentialRequest && Object.assign({}, (0, exports.validateCredentialRequestForm)(values.credentialRequest))
    };
    return errors;
};
exports.validateMultistepEnrollmentForm = validateMultistepEnrollmentForm;
/**
 * @fixme
 */
// eslint-disable-next-line complexity
const validatePersonForm = (person, cr) => {
    let errors = {};
    if (!person.lastName) {
        errors.lastName = 'Last Name Required';
    }
    if (!person.dateofbirth) {
        errors.dateofbirth = 'DOB Required';
    }
    if (!person.ssn && cr.licenseenhancement === 'REALID') {
        errors.ssn = 'SSN Required';
    }
    errors = verifySSN(errors, person.ssn);
    if (!person.gender) {
        errors.gender = 'Gender Required';
    }
    if (!person.eyecolor) {
        errors.eyecolor = 'Eye Color Required';
    }
    if (!person.weight) {
        errors.weight = 'Weight Required';
    }
    if (!person.heightfeet) {
        errors.heightfeet = 'Height (ft) Required';
    }
    if (!person.heightinches) {
        errors.heightinches = 'Height (in) Required';
    }
    return errors;
};
exports.validatePersonForm = validatePersonForm;
const validateCredentialRequestForm = (credentialRequest) => {
    const errors = {};
    if (!credentialRequest.licensetype) {
        errors.licensetype = 'Credential Type Required';
    }
    return errors;
};
exports.validateCredentialRequestForm = validateCredentialRequestForm;
