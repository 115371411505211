"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverrideModalPage = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const valid_ui_framework_1 = require("valid-ui-framework");
const valid_ui_components_1 = require("valid-ui-components");
const OverrideModalPage = ({ done, jumpTo, toggle, photoRecord, onOneToOneResolve, onIcaoResolve, onBioEnrollmentResolve, candidateImage, children }) => {
    var _a;
    const retryCapture = () => {
        if (jumpTo) {
            jumpTo('photo');
        }
    };
    (0, react_1.useEffect)(() => {
        if (!(photoRecord === null || photoRecord === void 0 ? void 0 : photoRecord.fileName) ||
            (photoRecord.icaoVerified === true ||
                photoRecord.icaoOverride === true) &&
                (photoRecord.oneToOneVerified === true ||
                    photoRecord.oneToOneOverride === true) &&
                (photoRecord.biometricEnrolled === true ||
                    photoRecord.biometricEnrollmentSkipped === true)) {
            done();
        }
    }, [photoRecord, done]);
    const [processingOverride, setProcessingOverride] = (0, react_1.useState)(false);
    const onOneToOne = (credentials, reset) => __awaiter(void 0, void 0, void 0, function* () {
        setProcessingOverride(true);
        yield onOneToOneResolve(credentials, reset)
            .finally(() => {
            setProcessingOverride(false);
        });
    });
    const onIcao = (credentials, reset) => __awaiter(void 0, void 0, void 0, function* () {
        setProcessingOverride(true);
        yield onIcaoResolve(credentials, reset)
            .finally(() => {
            setProcessingOverride(false);
        });
    });
    const onBioEnrollment = (credentials, reset) => __awaiter(void 0, void 0, void 0, function* () {
        setProcessingOverride(true);
        yield onBioEnrollmentResolve(credentials, reset)
            .finally(() => {
            setProcessingOverride(false);
        });
    });
    if (!(photoRecord === null || photoRecord === void 0 ? void 0 : photoRecord.fileName)) {
        return react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(reactstrap_1.ModalHeader, null, "No Overrides To Perform"),
            react_1.default.createElement(reactstrap_1.ModalBody, null),
            react_1.default.createElement(reactstrap_1.ModalFooter, null, children));
    }
    else if (photoRecord.icaoVerified !== true &&
        photoRecord.icaoOverride !== true) {
        return react_1.default.createElement(valid_ui_components_1.AdminIcaoOverrideModalContent, { headerText: "ICAO Override", bodyText: "Image has not passed ICAO quality checks. Please re-capture or override to continue.", toggle: toggle, image: photoRecord.image, failureReasons: photoRecord.icaoFailureReasons, resolveButtonText: "Override", retryButtonText: "Recapture Photo", retryAction: retryCapture, processingOverride: processingOverride, resolveAction: onIcao }, children);
    }
    else if (photoRecord.oneToOneOverride !== true &&
        photoRecord.oneToOneVerified !== true) {
        return react_1.default.createElement(valid_ui_components_1.AdminOneToOneOverrideContent, { headerText: "One To One Override", bodyText: "", probeImage: photoRecord.image, candidateImage: candidateImage, percent: (_a = photoRecord.oneToOneResult) === null || _a === void 0 ? void 0 : _a.score.toString(), failureReasons: photoRecord.oneToOneFailureReasons, resolveButtonText: "Override", retryButtonText: "Recapture Photo", retryAction: retryCapture, resolveAction: onOneToOne, processingOverride: processingOverride, toggle: toggle }, children);
    }
    else if (photoRecord.biometricEnrolled !== true &&
        photoRecord.biometricEnrollmentSkipped !== true) {
        return react_1.default.createElement(valid_ui_components_1.AdminOverrideModalContent, { bodyText: `
                This image was unable to be enrolled for biometric dedupe.
                You may attempt another capture or override and continue.
            `, headerText: "Biometric Enrollment Override", resolveButtonText: "Override", retryButtonText: "Recapture Photo", retryAction: retryCapture, resolveAction: onBioEnrollment, processingOverride: processingOverride }, children);
    }
    return react_1.default.createElement(react_1.default.Fragment, null);
};
exports.OverrideModalPage = OverrideModalPage;
const mapStateToProps = (state) => {
    return {
        photoRecord: state.workflows.multistepEnrollment.enrollment.activePhoto
    };
};
exports.default = (0, valid_ui_framework_1.frameworkConnect)(mapStateToProps)(exports.OverrideModalPage);
