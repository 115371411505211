"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restrictionExists = exports.endorsementExists = void 0;
const endorsementExists = (endorsements, endorsementCode) => {
    return (endorsements === null || endorsements === void 0 ? void 0 : endorsements.find((endorsement) => {
        return endorsement && endorsement.value === endorsementCode;
    })) ? true : false;
};
exports.endorsementExists = endorsementExists;
const restrictionExists = (restrictions, restrictionCode) => {
    return (restrictions === null || restrictions === void 0 ? void 0 : restrictions.find((restriction) => {
        return restriction && restriction.value === restrictionCode;
    })) ? true : false;
};
exports.restrictionExists = restrictionExists;
