"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEARDOWN_AGENCY_ENROLLMENT = exports.AGENCY_PRINT_RELEASE_FAILURE = exports.AGENCY_PRINT_RELEASE_SUCCESS = exports.AGENCY_PRINT_RELEASE_REQUEST = exports.GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_FAILURE = exports.GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_SUCCESS = exports.GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_REQUEST = exports.DELETE_AGENCY_ENROLLMENT_ADDRESS_FAILURE = exports.DELETE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS = exports.DELETE_AGENCY_ENROLLMENT_ADDRESS_REQUEST = exports.UPDATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE = exports.UPDATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS = exports.UPDATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST = exports.CREATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE = exports.CREATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS = exports.CREATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST = exports.GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_FAILURE = exports.GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_SUCCESS = exports.GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_REQUEST = exports.GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_FAILURE = exports.GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_SUCCESS = exports.GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_REQUEST = exports.CREATE_AGENCY_ENROLLMENT_SIGNATURE_FAILURE = exports.CREATE_AGENCY_ENROLLMENT_SIGNATURE_SUCCESS = exports.CREATE_AGENCY_ENROLLMENT_SIGNATURE_REQUEST = exports.CREATE_AGENCY_ENROLLMENT_PHOTO_FAILURE = exports.CREATE_AGENCY_ENROLLMENT_PHOTO_SUCCESS = exports.CREATE_AGENCY_ENROLLMENT_PHOTO_REQUEST = exports.UPDATE_AGENCY_ENROLLMENT_AGENT_FAILURE = exports.UPDATE_AGENCY_ENROLLMENT_AGENT_SUCCESS = exports.UPDATE_AGENCY_ENROLLMENT_AGENT_REQUEST = exports.CREATE_AGENCY_ENROLLMENT_AGENT_FAILURE = exports.CREATE_AGENCY_ENROLLMENT_AGENT_SUCCESS = exports.CREATE_AGENCY_ENROLLMENT_AGENT_REQUEST = exports.GET_AGENCY_ENROLLMENT_AGENT_FAILURE = exports.GET_AGENCY_ENROLLMENT_AGENT_SUCCESS = exports.GET_AGENCY_ENROLLMENT_AGENT_REQUEST = void 0;
exports.GET_AGENCY_ENROLLMENT_AGENT_REQUEST = 'GET_AGENCY_ENROLLMENT_AGENT_REQUEST';
exports.GET_AGENCY_ENROLLMENT_AGENT_SUCCESS = 'GET_AGENCY_ENROLLMENT_AGENT_SUCCESS';
exports.GET_AGENCY_ENROLLMENT_AGENT_FAILURE = 'GET_AGENCY_ENROLLMENT_AGENT_FAILURE';
exports.CREATE_AGENCY_ENROLLMENT_AGENT_REQUEST = 'CREATE_AGENCY_ENROLLMENT_AGENT_REQUEST';
exports.CREATE_AGENCY_ENROLLMENT_AGENT_SUCCESS = 'CREATE_AGENCY_ENROLLMENT_AGENT_SUCCESS';
exports.CREATE_AGENCY_ENROLLMENT_AGENT_FAILURE = 'CREATE_AGENCY_ENROLLMENT_AGENT_FAILURE';
exports.UPDATE_AGENCY_ENROLLMENT_AGENT_REQUEST = 'UPDATE_AGENCY_ENROLLMENT_AGENT_REQUEST';
exports.UPDATE_AGENCY_ENROLLMENT_AGENT_SUCCESS = 'UPDATE_AGENCY_ENROLLMENT_AGENT_SUCCESS';
exports.UPDATE_AGENCY_ENROLLMENT_AGENT_FAILURE = 'UPDATE_AGENCY_ENROLLMENT_AGENT_FAILURE';
exports.CREATE_AGENCY_ENROLLMENT_PHOTO_REQUEST = 'CREATE_AGENCY_ENROLLMENT_PHOTO_REQUEST';
exports.CREATE_AGENCY_ENROLLMENT_PHOTO_SUCCESS = 'CREATE_AGENCY_ENROLLMENT_PHOTO_SUCCESS';
exports.CREATE_AGENCY_ENROLLMENT_PHOTO_FAILURE = 'CREATE_AGENCY_ENROLLMENT_PHOTO_FAILURE';
exports.CREATE_AGENCY_ENROLLMENT_SIGNATURE_REQUEST = 'CREATE_AGENCY_ENROLLMENT_SIGNATURE_REQUEST';
exports.CREATE_AGENCY_ENROLLMENT_SIGNATURE_SUCCESS = 'CREATE_AGENCY_ENROLLMENT_SIGNATURE_SUCCESS';
exports.CREATE_AGENCY_ENROLLMENT_SIGNATURE_FAILURE = 'CREATE_AGENCY_ENROLLMENT_SIGNATURE_FAILURE';
exports.GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_REQUEST = 'GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_REQUEST';
exports.GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_SUCCESS = 'GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_SUCCESS';
exports.GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_FAILURE = 'GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_FAILURE';
exports.GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_REQUEST = 'GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_REQUEST';
exports.GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_SUCCESS = 'GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_SUCCESS';
exports.GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_FAILURE = 'GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_FAILURE';
exports.CREATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST = 'CREATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST';
exports.CREATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS = 'CREATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS';
exports.CREATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE = 'CREATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE';
exports.UPDATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST = 'UPDATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST';
exports.UPDATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS = 'UPDATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS';
exports.UPDATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE = 'UPDATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE';
exports.DELETE_AGENCY_ENROLLMENT_ADDRESS_REQUEST = 'DELETE_AGENCY_ENROLLMENT_ADDRESS_REQUEST';
exports.DELETE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS = 'DELETE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS';
exports.DELETE_AGENCY_ENROLLMENT_ADDRESS_FAILURE = 'DELETE_AGENCY_ENROLLMENT_ADDRESS_FAILURE';
exports.GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_REQUEST = 'GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_REQUEST';
exports.GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_SUCCESS = 'GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_SUCCESS';
exports.GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_FAILURE = 'GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_FAILURE';
exports.AGENCY_PRINT_RELEASE_REQUEST = 'AGENCY_PRINT_RELEASE_REQUEST';
exports.AGENCY_PRINT_RELEASE_SUCCESS = 'AGENCY_PRINT_RELEASE_SUCCESS';
exports.AGENCY_PRINT_RELEASE_FAILURE = 'AGENCY_PRINT_RELEASE_FAILURE';
exports.TEARDOWN_AGENCY_ENROLLMENT = 'TEARDOWN_AGENCY_ENROLLMENT';
