"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
class CompareModal extends react_1.Component {
    constructor() {
        super(...arguments);
        this.scaleImageOnLoad = (event) => {
            const image = event.currentTarget;
            if (image.naturalHeight > image.naturalWidth || image.naturalHeight === image.naturalWidth) {
                if (image.naturalHeight > image.parentElement.clientHeight) {
                    image.setAttribute('style', 'height: inherit');
                }
            }
            else {
                if (image.naturalWidth > image.parentElement.clientWidth) {
                    image.setAttribute('style', 'width: inherit');
                }
            }
        };
    }
    render() {
        const { isOpen, toggle, type, loadedItemImageSrc, selectedHistoryItemImageSrc } = this.props;
        const loadedItemImage = loadedItemImageSrc ? (react_1.default.createElement("img", { onLoad: this.scaleImageOnLoad, src: loadedItemImageSrc })) : null;
        const selectedHistoryImage = selectedHistoryItemImageSrc ? (react_1.default.createElement("img", { onLoad: this.scaleImageOnLoad, src: selectedHistoryItemImageSrc })) : null;
        return (react_1.default.createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: toggle, size: "lg" },
            react_1.default.createElement(reactstrap_1.ModalHeader, { toggle: toggle }, `Compare ${type}s`),
            react_1.default.createElement(reactstrap_1.ModalBody, null, loadedItemImageSrc && selectedHistoryItemImageSrc &&
                react_1.default.createElement(reactstrap_1.Row, null,
                    react_1.default.createElement(reactstrap_1.Col, { className: "card-management-image-container" },
                        react_1.default.createElement("div", { className: "compare-image-bounds" }, loadedItemImage)),
                    react_1.default.createElement(reactstrap_1.Col, { className: "card-management-image-container" },
                        react_1.default.createElement("div", { className: "compare-image-bounds" }, selectedHistoryImage)))),
            react_1.default.createElement(reactstrap_1.ModalFooter, null,
                react_1.default.createElement(reactstrap_1.Button, { onClick: toggle }, "Close"))));
    }
}
exports.default = CompareModal;
