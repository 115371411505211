"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchConfigs = void 0;
exports.SearchConfigs = {
    searchEntities: [
        'application'
    ],
    advSearchFields: [
        {
            fieldName: 'pid',
            prettyName: 'PID',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'issuedate',
            prettyName: 'Issue Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        }
    ],
    searchFields: [
        {
            fieldName: 'pid',
            prettyName: 'PID',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        }
    ],
    returnFields: [
        {
            fieldName: 'pid',
            prettyName: 'PID',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        },
        {
            fieldName: 'issuedate',
            prettyName: 'Issue Date',
            fieldType: 'date'
        },
        {
            fieldName: 'printeddate',
            prettyName: 'Printed Date',
            fieldType: 'date'
        },
        {
            fieldName: 'licensetype',
            prettyName: 'License Type',
            fieldType: 'string'
        },
        {
            fieldName: 'servicetype',
            prettyName: 'Service Type',
            fieldType: 'string'
        },
        {
            fieldName: 'expirationdate',
            prettyName: 'Expiration Date',
            fieldType: 'date',
            renderColumn: false
        },
        {
            fieldName: 'factoryupdaterecievedate',
            prettyName: 'Factory Update',
            fieldType: 'date',
            renderColumn: false
        },
        {
            fieldName: 'status',
            prettyName: 'Status',
            fieldType: 'date',
            renderColumn: false
        },
        {
            fieldName: 'transactionid',
            prettyName: 'Transaction ID',
            fieldType: 'string',
            renderColumn: false
        }
    ],
    staticFilter: [
        {
            field: 'active',
            searchType: 'exclusive',
            term: true
        }
    ],
    staticSort: [
        {
            field: 'issuedate',
            sortDirection: 'desc'
        }
    ]
};
