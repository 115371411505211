"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settings = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const WizardOrderConfigurator_1 = require("./WizardOrderConfigurator");
const Settings = ({ name, isOpen, modals, settings, onSettingsChange, children }) => {
    const autostart = settings === null || settings === void 0 ? void 0 : settings.autostart;
    const preExistingModalOrder = settings === null || settings === void 0 ? void 0 : settings.modalOrder;
    const preExistingConfiguredProps = settings === null || settings === void 0 ? void 0 : settings.configurableProps;
    const [isAuto, setIsAuto] = (0, react_1.useState)(autostart || false);
    const [configurableProps, setConfigurableProps] = (0, react_1.useState)(preExistingConfiguredProps);
    const [modalOrder, setModalOrder] = (0, react_1.useState)(preExistingModalOrder);
    const rearrangeModalOrder = (newModalOrder) => {
        setModalOrder(newModalOrder);
    };
    const updateConfigurableProps = (componentName, propName, props) => {
        setConfigurableProps((confProps) => {
            var _a;
            const componentPropConfigurations = (_a = confProps === null || confProps === void 0 ? void 0 : confProps[componentName]) !== null && _a !== void 0 ? _a : {};
            return Object.assign(Object.assign({}, confProps), { [componentName]: Object.assign(Object.assign({}, componentPropConfigurations), { [propName]: props }) });
        });
    };
    const didMountRef = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        if (didMountRef.current) {
            onSettingsChange({
                autostart: isAuto,
                modalOrder: modalOrder,
                configurableProps: configurableProps
            });
        }
        else {
            didMountRef.current = true;
        }
    }, [modalOrder, configurableProps, isAuto, onSettingsChange]);
    const onAutoUpdate = (e) => {
        setIsAuto(e.currentTarget.checked);
    };
    return react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(reactstrap_1.ModalHeader, null, "Wizard Configuration"),
        react_1.default.createElement(reactstrap_1.ModalBody, null,
            !modalOrder || (modalOrder === null || modalOrder === void 0 ? void 0 : modalOrder.length) < 1 ?
                react_1.default.createElement(reactstrap_1.Alert, { color: "warning" },
                    react_1.default.createElement("p", null, "No pages are configured for display."),
                    react_1.default.createElement("p", null,
                        "Configure pages to be displayed by dragging available pages over to the ",
                        react_1.default.createElement("strong", null,
                            name,
                            " Page Order"),
                        " and saving."))
                : null,
            react_1.default.createElement(WizardOrderConfigurator_1.WizardOrderConfigurator, { isOpen: isOpen, modals: modals, preExistingModalOrder: modalOrder, preExistingModalConfigs: configurableProps, onModalOrderChange: rearrangeModalOrder, onConfigurablePropsChange: updateConfigurableProps })),
        react_1.default.createElement(reactstrap_1.ModalFooter, null,
            react_1.default.createElement("div", { className: "custom-control custom-checkbox autostart" },
                react_1.default.createElement("input", { id: "wizardAutoStart", type: "checkbox", className: "custom-control-input", checked: isAuto, onChange: onAutoUpdate }),
                react_1.default.createElement("label", { htmlFor: "wizardAutoStart", className: "custom-control-label" }, "Autostart Wizard")),
            children));
};
exports.Settings = Settings;
