"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actionMap = {
    GET_PRE_ENROLLMENT_PERSON_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { preEnrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.preEnrollment), { person: action.payload }) });
    },
    GET_PRE_ENROLLMENT_CR_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { preEnrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.preEnrollment), { credentialRequest: action.payload }) });
    },
    GET_PRE_ENROLLMENT_PERSON_FINALIZATION_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { preEnrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.preEnrollment), { personFinalization: action.payload }) });
    },
    GET_PRE_ENROLLMENT_CR_FINALIZATION_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { preEnrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.preEnrollment), { crFinalization: action.payload }) });
    },
    GET_ENROLLMENT_PERSON_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { person: action.payload }) });
    },
    GET_ENROLLMENT_CR_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { credentialRequest: action.payload }) });
    },
    GET_MULTISTEP_ENROLLMENT_PERSON_FINALIZATION_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { personFinalization: action.payload }) });
    },
    GET_MULTISTEP_ENROLLMENT_CR_FINALIZATION_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { credentialFinalization: action.payload }) });
    },
    CREATE_MULTISTEP_ENROLLMENT_PERSON_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { person: action.payload }) });
    },
    CREATE_MULTISTEP_ENROLLMENT_CR_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { credentialRequest: action.payload }) });
    },
    UPDATE_MULTISTEP_ENROLLMENT_PERSON_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { person: action.payload }) });
    },
    UPDATE_MULTISTEP_ENROLLMENT_CR_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { credentialRequest: action.payload }) });
    },
    CREATE_MULTISTEP_ENROLLMENT_PHOTO_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        const newPhotoState = [];
        newPhotoState.push(action.payload);
        multistepEnrollmentWorkflowState.enrollment.person.photos = newPhotoState;
        return multistepEnrollmentWorkflowState;
    },
    CREATE_MULTISTEP_ENROLLMENT_SIGNATURE_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        const newPhotoState = [];
        newPhotoState.push(action.payload);
        multistepEnrollmentWorkflowState.enrollment.person.signatures = newPhotoState;
        return multistepEnrollmentWorkflowState;
    },
    CREATE_MULTISTEP_ENROLLMENT_ADDRESS_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { person: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment.person), { addresses: [
                        ...multistepEnrollmentWorkflowState.enrollment.person.addresses,
                        action.payload
                    ] }) }) });
    },
    EDIT_MULTISTEP_ENROLLMENT_ADDRESS_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { person: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment.person), { addresses: multistepEnrollmentWorkflowState.enrollment.person.addresses.map((address) => {
                        if (address.id === action.payload.id) {
                            return action.payload;
                        }
                        return address;
                    }) }) }) });
    },
    DELETE_MULTISTEP_ENROLLMENT_ADDRESS_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { person: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment.person), { addresses: multistepEnrollmentWorkflowState.enrollment.person.addresses.filter((address) => {
                        return address.id !== action.payload;
                    }) }) }) });
    },
    GET_ACTIVE_PHOTO_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { activePhoto: action.payload }) });
    },
    GET_ACTIVE_SIGNATURE_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { activeSignature: action.payload }) });
    },
    CLONE_MULTISTEP_ENROLLMENT_CREDENTIAL_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { credentialRequest: action.payload }) });
    },
    CLONE_MULTISTEP_ENROLLMENT_IDENTITY_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { person: action.payload }) });
    },
    MULTISTEP_SSOLV_CHECK_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign({}, multistepEnrollmentWorkflowState.enrollment) });
    },
    MULTISTEP_USPASS_CHECK_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign({}, multistepEnrollmentWorkflowState.enrollment) });
    },
    GET_MULTISTEP_WORK_ITEM_TYPES_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { workItemTypes: action.payload.data }) });
    },
    MULTISTEP_GET_COMMENTS_SUCCESS: (multiStepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multiStepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multiStepEnrollmentWorkflowState.enrollment), { comments: action.payload }) });
    },
    MULTISTEP_CREATE_COMMENT_SUCCESS: (multistepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multistepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment), { comments: Object.assign(Object.assign({}, multistepEnrollmentWorkflowState.enrollment.comments), { data: [...multistepEnrollmentWorkflowState.enrollment.comments.data, action.payload] }) }) });
    },
    MULTISTEP_UPDATE_COMMENT_SUCCESS: (multiStepEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, multiStepEnrollmentWorkflowState), { enrollment: Object.assign(Object.assign({}, multiStepEnrollmentWorkflowState.enrollment), { comments: Object.assign(Object.assign({}, multiStepEnrollmentWorkflowState.enrollment.comments), { data: multiStepEnrollmentWorkflowState.enrollment.comments.data.map((comment) => {
                        if (comment.id === action.payload.id) {
                            return action.payload;
                        }
                        return comment;
                    }) }) }) });
    },
    TEARDOWN_MULTISTEP_ENROLLMENT_WORKFLOW: (multistepEnrollmentWorkflowState, action) => {
        return {
            preEnrollment: {
                person: {},
                credentialRequest: {},
                personFinalization: {},
                crFinalization: {}
            },
            enrollment: {
                credentialFinalization: {},
                personFinalization: {},
                person: {},
                activePhoto: {},
                activeSignature: {},
                credentialRequest: {},
                documentVerification: {},
                workItems: []
            }
        };
    }
};
const multistepEnrollmentHandler = (multistepEnrollmentWorkflowState, action) => {
    if (actionMap[action.type]) {
        return actionMap[action.type](multistepEnrollmentWorkflowState, action);
    }
    else {
        return multistepEnrollmentWorkflowState;
    }
};
exports.default = multistepEnrollmentHandler;
