"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.landPageConfig = void 0;
exports.landPageConfig = [
    {
        title: 'Home',
        route: '/',
        icon: 'fas fa-home'
    },
    {
        title: 'Single Form Enrollment',
        route: '/single-form-enrollment',
        icon: 'fa-user-plus'
    },
    {
        title: 'Multistep Enrollment',
        route: '/enrollment',
        icon: 'fa-user-plus'
    },
    {
        title: 'Merge Person',
        route: '/merge-person',
        icon: 'fas fa-user-slash'
    },
    {
        title: 'Work Items',
        route: '/work-items',
        icon: 'fas fa-toolbox'
    },
    {
        title: 'Application Review',
        route: '/application-review',
        icon: 'fas fa-th-list'
    },
    {
        title: 'Account History',
        route: '/account-history',
        icon: 'fa-id-card'
    },
    {
        title: 'Print Queue',
        route: '/print-queue',
        icon: 'fas fa-print'
    },
    {
        title: 'Unclaimed Credentials',
        route: '/unclaimed-credential',
        icon: 'fa-envelope'
    },
    {
        title: 'Queues',
        route: '/queues-view',
        icon: 'fas fa-chart-line'
    },
    {
        title: 'Audit Logs',
        route: '/audit-logs',
        icon: 'fas fa-user-secret'
    },
    {
        title: 'VIC Settings',
        route: '/vic-settings',
        icon: 'fas fa-cogs'
    },
    {
        title: 'About',
        route: '/about',
        icon: 'fas fa-compass'
    },
    {
        title: 'User Profile',
        route: '/user-profile',
        icon: 'fas fa-portrait'
    }
];
