"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.personHasPersonAddressType = exports.personHasAddressType = void 0;
const personHasAddressType = (addresses, addressType) => {
    const addressTypeMap = {
        Mailing: 2,
        Residential: 0
    };
    return addresses.find((address) => address.addressType === addressTypeMap[addressType]) ?
        true : false;
};
exports.personHasAddressType = personHasAddressType;
const personHasPersonAddressType = (addresses, addressType) => {
    const addressTypeMap = {
        Mailing: 2,
        Residential: 0
    };
    return addresses.find((address) => address.addressType === addressTypeMap[addressType]) ?
        true : false;
};
exports.personHasPersonAddressType = personHasPersonAddressType;
