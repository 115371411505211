"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageSrc = void 0;
const getImageSrc = (imageModel) => {
    if (imageModel) {
        try {
            const url = new URL(imageModel.image);
            return url.href;
        }
        catch (_a) {
            return `data:image;base64,${imageModel.base64String}` ||
                `data:image;base64,${imageModel.image}`;
        }
    }
    return null;
};
exports.getImageSrc = getImageSrc;
