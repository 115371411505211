"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyAddress = void 0;
const types_1 = require("./types");
const Api_1 = require("../../../utilities/Api");
const valid_ui_components_1 = require("valid-ui-components");
const verifyAddress = (address) => (dispatch) => {
    return (0, valid_ui_components_1.postItemAction)({
        dispatch: dispatch,
        request: types_1.VERIFY_ADDRESS_REQUEST,
        success: types_1.VERIFY_ADDRESS_SUCCESS,
        failure: types_1.VERIFY_ADDRESS_FAILURE,
        url: 'address',
        api: Api_1.addressVerificationApi,
        bodyObj: address
    }).then((res) => {
        return {
            originalAddress: address,
            verificationResponse: res.data
        };
    });
};
exports.verifyAddress = verifyAddress;
