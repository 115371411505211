"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const redux_1 = require("redux");
const connected_react_router_1 = require("connected-react-router");
const valid_ui_framework_1 = require("valid-ui-framework");
class ReissueBusinessErrorsModal extends react_1.Component {
    constructor() {
        super(...arguments);
        this.navigateToLicenseIssuance = () => {
            this.props.push(`enrollment?person=${this.props.personId}&cr=${this.props.credentialRequestId}`);
        };
    }
    render() {
        const { isOpen, toggle } = this.props;
        return (react_1.default.createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: toggle },
            react_1.default.createElement("h5", { className: "modal-header" }, "Error Processing Duplicate Credential"),
            react_1.default.createElement(reactstrap_1.ModalBody, null, "The request to duplicate the credential was started, however some business rule violations occured. Would you like to navigate to the Enrollment workflow to resolve these violations?"),
            react_1.default.createElement(reactstrap_1.ModalFooter, null,
                react_1.default.createElement(reactstrap_1.Button, { color: "primary", onClick: this.navigateToLicenseIssuance }, "Yes"),
                react_1.default.createElement(reactstrap_1.Button, { onClick: toggle }, "No"))));
    }
}
const mapDispatchToProps = (dispatch) => {
    return (0, redux_1.bindActionCreators)({
        push: connected_react_router_1.push
    }, dispatch);
};
exports.default = (0, valid_ui_framework_1.frameworkConnect)(null, mapDispatchToProps)(ReissueBusinessErrorsModal);
