"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.limitAddressTypeSelection = void 0;
const addressTypeMap = {
    Residential: 0,
    Commercial: 1,
    Mailing: 2,
    SpecialMailing: 3,
    POBox: 4
};
const limitAddressTypeSelection = (addressType, addresses) => {
    let addressExists = false;
    addresses.forEach((address) => {
        if (addressTypeMap[addressType] === address.addressType) {
            addressExists = true;
        }
    });
    return addressExists;
};
exports.limitAddressTypeSelection = limitAddressTypeSelection;
