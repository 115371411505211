"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptureWizard = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const WizardSettings_1 = require("./WizardSettings");
const WizardPages_1 = require("./WizardPages");
const valid_ui_components_1 = require("valid-ui-components");
const defaultSettings = {
    modalOrder: [],
    autostart: false,
    configurableProps: {}
};
// 🧙‍♂️
const CaptureWizard = ({ name, modals, openButtonText, buttonClassName, settings, onSettingsUpdate }) => {
    // const {
    //     modalOrder,
    //     autostart,
    //     configurableProps
    // } = initialSettings;
    var _a, _b, _c;
    const providedModals = Object.keys(modals);
    const [isSavingSettings, setSavingSettings] = (0, react_1.useState)(false);
    const [showCaptures, setShowCaptures] = (0, react_1.useState)(false);
    const [showSettings, setShowSettings] = (0, react_1.useState)(false);
    const [newSettings, setNewSettings] = (0, react_1.useState)(defaultSettings);
    const orderedModals = (_a = newSettings === null || newSettings === void 0 ? void 0 : newSettings.modalOrder) === null || _a === void 0 ? void 0 : _a.filter(mod => {
        return providedModals.includes(mod);
    });
    const toggleSettings = (0, react_1.useCallback)(() => {
        setStarted(true);
        setShowCaptures(false);
        setShowSettings(!showSettings);
    }, [showSettings]);
    const toggleCaptures = (0, react_1.useCallback)(() => {
        var _a;
        setStarted(true);
        if (((_a = newSettings === null || newSettings === void 0 ? void 0 : newSettings.modalOrder) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            setShowSettings(false);
            setShowCaptures(!showCaptures);
        }
        else {
            toggleSettings();
        }
    }, [(_b = newSettings === null || newSettings === void 0 ? void 0 : newSettings.modalOrder) === null || _b === void 0 ? void 0 : _b.length, toggleSettings, showCaptures]);
    (0, react_1.useEffect)(() => {
        setNewSettings(Object.assign({}, settings));
    }, [settings]);
    const [started, setStarted] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (newSettings.autostart && !started) {
            setStarted(true);
            toggleCaptures();
        }
    }, [newSettings, started, toggleCaptures]);
    const saveSettings = () => {
        setSavingSettings(true);
        onSettingsUpdate(newSettings)
            .finally(() => {
            setSavingSettings(false);
        });
    };
    const updateSettings = (0, react_1.useCallback)((newSettings) => {
        setNewSettings(Object.assign({}, newSettings));
    }, []);
    const saveSettingsAndStart = () => {
        setSavingSettings(true);
        onSettingsUpdate(newSettings)
            .finally(() => {
            setSavingSettings(false);
            toggleCaptures();
        });
    };
    return react_1.default.createElement("div", { className: "wizard-launcher" },
        react_1.default.createElement(reactstrap_1.ButtonGroup, { className: buttonClassName },
            react_1.default.createElement(reactstrap_1.Button, { color: "primary", onClick: toggleCaptures }, openButtonText),
            react_1.default.createElement(reactstrap_1.Button, { color: "primary", onClick: toggleSettings },
                react_1.default.createElement("i", { className: "fas fa-cog" }))),
        react_1.default.createElement(reactstrap_1.Modal, { size: "lg", toggle: toggleCaptures, isOpen: showCaptures },
            react_1.default.createElement(WizardPages_1.WizardPages, { pages: orderedModals === null || orderedModals === void 0 ? void 0 : orderedModals.map(mod => {
                    var _a;
                    const page = {
                        id: mod,
                        props: modals[mod].props,
                        configurableProps: (_a = newSettings === null || newSettings === void 0 ? void 0 : newSettings.configurableProps) === null || _a === void 0 ? void 0 : _a[mod],
                        Component: modals[mod].Component,
                        additionalFormElements: modals[mod].additionalFormElements
                    };
                    return page;
                }), onCompletion: toggleCaptures })),
        react_1.default.createElement(reactstrap_1.Modal, { size: "lg", className: "settings", toggle: toggleSettings, isOpen: showSettings },
            react_1.default.createElement(WizardSettings_1.Settings, { name: name, settings: newSettings, modals: modals, onSettingsChange: updateSettings, isOpen: showSettings },
                react_1.default.createElement(valid_ui_components_1.IconButton, { defaultIconClass: false, id: "ApplicationReviewReleaseToPrint", className: "btn btn-primary", loading: isSavingSettings, onClick: saveSettings, title: "Release To Print", spinnerSize: 1 }, "Save"),
                react_1.default.createElement(valid_ui_components_1.IconButton, { defaultIconClass: false, id: "ApplicationReviewReleaseToPrint", className: "btn btn-success", onClick: saveSettingsAndStart, loading: isSavingSettings, title: "Release To Print", spinnerSize: 1 },
                    "Save and ",
                    react_1.default.createElement("span", null, ((_c = newSettings === null || newSettings === void 0 ? void 0 : newSettings.modalOrder) === null || _c === void 0 ? void 0 : _c.length) > 0 ?
                        react_1.default.createElement(react_1.default.Fragment, null, "Start Wizard") :
                        react_1.default.createElement(react_1.default.Fragment, null, "Close"))),
                react_1.default.createElement(reactstrap_1.Button, { color: "secondary", onClick: toggleSettings }, "Close"))));
};
exports.CaptureWizard = CaptureWizard;
