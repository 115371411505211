"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CANCEL_APP_REVIEW_APPLICATION_FAILURE = exports.CANCEL_APP_REVIEW_APPLICATION_SUCCESS = exports.CANCEL_APP_REVIEW_APPLICATION_REQUEST = exports.UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_FAILURE = exports.UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_SUCCESS = exports.UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_REQUEST = exports.FACTORY_PULL_APP_REVIEW_CR_FAILURE = exports.FACTORY_PULL_APP_REVIEW_CR_SUCCESS = exports.FACTORY_PULL_APP_REVIEW_CR_REQUEST = exports.PERSON_SUBMIT_RELEASE_APP_REVIEW_SUCCESS = exports.PRINT_RELEASE_APP_REVIEW_SUCCESS = exports.GET_APP_REVIEW_WORK_ITEM_TYPES_SUCCESS = exports.GET_APP_REVIEW_WORK_ITEM_TYPE_REASONS_SUCCESS = exports.CREATE_APP_REVIEW_WORK_ITEM_SUCCESS = void 0;
exports.CREATE_APP_REVIEW_WORK_ITEM_SUCCESS = 'GET_APP_REVIEW_WORK_ITEM_TYPES_SUCCESS';
exports.GET_APP_REVIEW_WORK_ITEM_TYPE_REASONS_SUCCESS = 'GET_APP_REVIEW_WORK_ITEM_TYPES_SUCCESS';
exports.GET_APP_REVIEW_WORK_ITEM_TYPES_SUCCESS = 'GET_APP_REVIEW_WORK_ITEM_TYPES_SUCCESS';
exports.PRINT_RELEASE_APP_REVIEW_SUCCESS = 'PRINT_RELEASE_APP_REVIEW_SUCCESS';
exports.PERSON_SUBMIT_RELEASE_APP_REVIEW_SUCCESS = 'PERSON_SUBMIT_RELEASE_APP_REVIEW_SUCCESS';
exports.FACTORY_PULL_APP_REVIEW_CR_REQUEST = 'FACTORY_PULL_APP_REVIEW_CR_REQUEST';
exports.FACTORY_PULL_APP_REVIEW_CR_SUCCESS = 'FACTORY_PULL_APP_REVIEW_CR_SUCCESS';
exports.FACTORY_PULL_APP_REVIEW_CR_FAILURE = 'FACTORY_PULL_APP_REVIEW_CR_FAILURE';
exports.UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_REQUEST = 'UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_REQUEST';
exports.UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_SUCCESS = 'UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_SUCCESS';
exports.UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_FAILURE = 'UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_FAILURE';
exports.CANCEL_APP_REVIEW_APPLICATION_REQUEST = 'CANCEL_LICENSE_ISSUANCE_APPLICATION_REQUEST';
exports.CANCEL_APP_REVIEW_APPLICATION_SUCCESS = 'CANCEL_LICENSE_ISSUANCE_APPLICATION_SUCCESS';
exports.CANCEL_APP_REVIEW_APPLICATION_FAILURE = 'CANCEL_LICENSE_ISSUANCE_APPLICATION_FAILURE';
