"use strict";
/**
 * @deprecated
 * Leaving incase we add agency enrollment back in. VT is the only on that has it and would use the
 *  these function so all you would have to do is uncomment them.
 *  Would prefer if added in we would fix the actions appropriately though.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancelApplication = exports.populateNextQueuedPatron = exports.getItemWithHistory = void 0;
const baseActions_1 = require("./baseActions");
Object.defineProperty(exports, "getItemWithHistory", { enumerable: true, get: function () { return baseActions_1.getItemWithHistory; } });
Object.defineProperty(exports, "cancelApplication", { enumerable: true, get: function () { return baseActions_1.cancelApplication; } });
const queues_1 = require("./queues");
Object.defineProperty(exports, "populateNextQueuedPatron", { enumerable: true, get: function () { return queues_1.populateNextQueuedPatron; } });
