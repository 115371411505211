"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSSN = void 0;
const formatSSN = (value) => {
    if (!value) {
        return value;
    }
    if (value !== '********') {
        const onlyNumbers = value.replace(/[^\d]/g, '');
        return `${onlyNumbers.slice(0, 9)}`;
    }
    return value;
};
exports.formatSSN = formatSSN;
