"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const redux_1 = require("redux");
const valid_ui_components_1 = require("valid-ui-components");
const valid_ui_framework_1 = require("valid-ui-framework");
const appReviewActions_1 = require("../../../redux/actions/applicationReview/appReviewActions");
const wf_app_review_1 = require("wf-app-review");
const ReleaseToPrintButton = (props) => {
    var _a, _b, _c, _d;
    const [loading, setLoading] = (0, react_1.useState)(false);
    const submitApplication = () => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f, _g, _h, _j, _k, _l, _m;
        setLoading(true);
        if (!((_f = (_e = props.credentialRequestFinalization) === null || _e === void 0 ? void 0 : _e.status) === null || _f === void 0 ? void 0 : _f.ReleasedToPrint)) {
            yield props.finalPrintRelease(props.credentialRequest.id);
        }
        if (!((_h = (_g = props.personFinalization) === null || _g === void 0 ? void 0 : _g.status) === null || _h === void 0 ? void 0 : _h.SubmittedForFinalization)) {
            yield props.finalPersonRelease(props.person.id);
        }
        if (!((_k = (_j = props.personFinalization) === null || _j === void 0 ? void 0 : _j.status) === null || _k === void 0 ? void 0 : _k.SubmittedForFinalization)
            || !((_m = (_l = props.credentialRequestFinalization) === null || _l === void 0 ? void 0 : _l.status) === null || _m === void 0 ? void 0 : _m.ReleasedToPrint)) {
            Promise.all([
                props.getAppReviewPersonFinalization(props.person.id),
                props.getAppReviewPerson(props.person.id),
                props.getAppReviewCredentialRequestFinalization(props.credentialRequest.id),
                props.getAppReviewCredentialRequest(props.credentialRequest.id)
            ])
                .then(() => {
                (0, valid_ui_components_1.successToast)('Application Submitted and Released to print!');
                setLoading(false);
            })
                .catch((err) => {
                (0, valid_ui_components_1.errorToast)('Application Failed to Release to print!', err);
                setLoading(false);
            });
        }
    });
    return props.credentialRequest.businessRuleValidation.success
        && props.person.businessRuleValidation.success
        && (!((_b = (_a = props.personFinalization) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.SubmittedForFinalization)
            || !((_d = (_c = props.credentialRequestFinalization) === null || _c === void 0 ? void 0 : _c.status) === null || _d === void 0 ? void 0 : _d.ReleasedToPrint))
        ? (react_1.default.createElement(valid_ui_components_1.IconButton, { defaultIconClass: false, id: "ApplicationReviewReleaseToPrint", className: "btn btn-success", title: "Release To Print", onClick: submitApplication, loading: loading, spinnerSize: 1 }, "Release To Print"))
        : react_1.default.createElement(react_1.default.Fragment, null);
};
const mapStateToProps = (state) => {
    return {
        credentialRequest: state.applicationReview.credentialRequest,
        person: state.applicationReview.person,
        personFinalization: state.applicationReview.personFinalization,
        credentialRequestFinalization: state.applicationReview.credentialRequestFinalization
    };
};
const mapDispatchToProps = (dispatch) => (0, redux_1.bindActionCreators)({
    finalPrintRelease: appReviewActions_1.finalPrintRelease,
    finalPersonRelease: appReviewActions_1.finalPersonRelease,
    getAppReviewCredentialRequest: wf_app_review_1.getAppReviewCredentialRequest,
    getAppReviewPerson: wf_app_review_1.getAppReviewPerson,
    getAppReviewCredentialRequestFinalization: wf_app_review_1.getAppReviewCredentialRequestFinalization,
    getAppReviewPersonFinalization: wf_app_review_1.getAppReviewPersonFinalization
}, dispatch);
exports.default = (0, valid_ui_framework_1.frameworkConnect)(mapStateToProps, mapDispatchToProps)(ReleaseToPrintButton);
