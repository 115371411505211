"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const searchConfig = {
    searchEntities: [
        'application'
    ],
    advSearchFields: [
        {
            fieldName: 'status',
            prettyName: 'App Status',
            fieldType: 'select',
            optionsList: [
                {
                    label: 'Enrollment',
                    value: 'enrollment'
                },
                {
                    label: 'Release Readiness',
                    value: 'readiness'
                },
                {
                    label: 'Queue',
                    value: 'queue'
                },
                {
                    label: 'Hold',
                    value: 'hold'
                },
                {
                    label: 'Canceled',
                    value: 'canceled'
                },
                {
                    label: 'Printed',
                    value: 'printed'
                },
                {
                    label: 'Reconciled',
                    value: 'reconciled'
                },
                {
                    label: 'Unclaimed',
                    value: 'unclaimed'
                }
            ]
        },
        {
            fieldName: 'pid',
            prettyName: 'DLN',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        }
    ],
    searchFields: [
        {
            fieldName: 'status',
            prettyName: 'App Status',
            fieldType: 'string'
        },
        {
            fieldName: 'pid',
            prettyName: 'DLN',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        }
    ],
    returnFields: [
        {
            fieldName: 'status',
            prettyName: 'App Status',
            fieldType: 'select',
            optionsList: [
                {
                    label: 'Enrollment',
                    value: 'enrollment'
                },
                {
                    label: 'Release Readiness',
                    value: 'readiness'
                },
                {
                    label: 'Hold',
                    value: 'hold'
                },
                {
                    label: 'Queue',
                    value: 'queue'
                },
                {
                    label: 'Canceled',
                    value: 'canceled'
                },
                {
                    label: 'Printed',
                    value: 'printed'
                },
                {
                    label: 'Reconciled',
                    value: 'reconciled'
                },
                {
                    label: 'Unclaimed',
                    value: 'unclaimed'
                }
            ]
        },
        {
            fieldName: 'pid',
            prettyName: 'DLN',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        },
        {
            fieldName: 'issuedate',
            prettyName: 'Issue Date',
            fieldType: 'date'
        },
        {
            fieldName: 'licensetype',
            prettyName: 'License Type',
            fieldType: 'string'
        },
        {
            fieldName: 'servicetype',
            prettyName: 'Service Type',
            fieldType: 'string'
        }
    ],
    staticFilter: [
        {
            field: 'active',
            searchType: 'exclusive',
            term: true
        }
    ],
    staticSort: [
        {
            field: 'issuedate',
            sortDirection: 'desc'
        }
    ]
};
exports.default = searchConfig;
