"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pcwConfigs = void 0;
exports.pcwConfigs = {
    captureMethods: [
        'mockcamera',
        'canoncb',
        'webcam',
        'file',
        'canon',
        'getcamera',
        'twain'
    ],
    defaultCaptureMethod: 'getcamera',
    displayBadges: true,
    preCrop: false,
    postCrop: false,
    defaultTwainDevice: '',
    HWSCanonDefaults: {
        cameraName: 'Canon EOS Rebel T7',
        previewQuality: '269745935',
        snapshotQuality: '18087695',
        cameraRotation: 270
    }
};
