"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const searchConfig = {
    searchEntities: [
        'issuance'
    ],
    advSearchFields: [
        {
            fieldName: 'pid',
            prettyName: 'DLN',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        }
    ],
    searchFields: [
        {
            fieldName: 'pid',
            prettyName: 'DLN',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        }
    ],
    returnFields: [
        {
            fieldName: 'pid',
            prettyName: 'DLN',
            fieldType: 'string'
        },
        {
            fieldName: 'firstname',
            prettyName: 'First Name',
            fieldType: 'string'
        },
        {
            fieldName: 'middlename',
            prettyName: 'Middle Name',
            fieldType: 'string'
        },
        {
            fieldName: 'lastname',
            prettyName: 'Last Name',
            fieldType: 'string'
        },
        {
            fieldName: 'dateofbirth',
            prettyName: 'Birth Date',
            fieldType: 'date'
        },
        {
            fieldName: 'zip',
            prettyName: 'Zip Code',
            fieldType: 'string'
        },
        {
            fieldName: 'issuedate',
            prettyName: 'Issue Date',
            fieldType: 'date'
        },
        {
            fieldName: 'licensetype',
            prettyName: 'License Type',
            fieldType: 'string'
        },
        {
            fieldName: 'servicetype',
            prettyName: 'Service Type',
            fieldType: 'string'
        }
    ],
    staticFilter: [
        {
            field: 'active',
            searchType: 'exclusive',
            term: true
        },
        {
            field: 'licensetype',
            searchType: 'inclusive',
            term: 'DL%'
        },
        {
            field: 'licensetype',
            searchType: 'inclusive',
            term: 'ID%'
        },
        {
            field: 'licensetype',
            searchType: 'inclusive',
            term: 'RIDL%'
        },
        {
            field: 'licensetype',
            searchType: 'inclusive',
            term: 'RIID%'
        }
    ],
    staticSort: [
        {
            field: 'issuedate',
            sortDirection: 'desc'
        }
    ]
};
exports.default = searchConfig;
