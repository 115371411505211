"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const _1 = require(".");
class RealIdFormPages extends react_1.Component {
    render() {
        const { page, previousPage, personPageSubmit, credentialRequestPageSubmit, capturePageSubmit, dataVerifyPageSubmit, handleFormSubmit, formLoading, isRealId, navToPage, onPageJump } = this.props;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            page === 2 &&
                react_1.default.createElement(_1.CapturePage, { formLoading: formLoading, toggleFormLoadingState: this.props.toggleFormLoadingState, onSubmit: capturePageSubmit, previous: previousPage, toggleCancelConfirmationModal: this.props.toggleCancelConfirmationModal, toggleCommentModal: this.props.toggleCommentModal, refreshApplication: this.props.refreshApplication, workItemWidget: this.props.workItemWidget, uiConfigs: this.props.uiConfigs, HWSReady: this.props.HWSReady, setNavState: this.props.setNavState, isRealId: isRealId, navToPage: navToPage, onPageJump: onPageJump, getEnrollmentPerson: this.props.getEnrollmentPerson }),
            page === 3 &&
                react_1.default.createElement(_1.PersonPage, { formLoading: formLoading, toggleFormLoadingState: this.props.toggleFormLoadingState, previous: previousPage, onSubmit: personPageSubmit, toggleCancelConfirmationModal: this.props.toggleCancelConfirmationModal, toggleCommentModal: this.props.toggleCommentModal, refreshApplication: this.props.refreshApplication, workItemWidget: this.props.workItemWidget, setNavState: this.props.setNavState, isRealId: isRealId, navToPage: navToPage, onPageJump: onPageJump, getEnrollmentPerson: this.props.getEnrollmentPerson }),
            page === 4 &&
                react_1.default.createElement(_1.CredentialRequestPage, { formLoading: formLoading, toggleFormLoadingState: this.props.toggleFormLoadingState, toggleCancelConfirmationModal: this.props.toggleCancelConfirmationModal, toggleCommentModal: this.props.toggleCommentModal, refreshApplication: this.props.refreshApplication, previous: previousPage, onSubmit: credentialRequestPageSubmit, workItemWidget: this.props.workItemWidget, setNavState: this.props.setNavState, isRealId: isRealId, navToPage: navToPage, onPageJump: onPageJump }),
            page === 5 &&
                react_1.default.createElement(_1.DataVerificationPage, { formLoading: formLoading, toggleFormLoadingState: this.props.toggleFormLoadingState, onSubmit: dataVerifyPageSubmit, toggleCancelConfirmationModal: this.props.toggleCancelConfirmationModal, toggleCommentModal: this.props.toggleCommentModal, refreshApplication: this.props.refreshApplication, previous: previousPage, workItemWidget: this.props.workItemWidget, isRealId: isRealId, navToPage: navToPage, onPageJump: onPageJump }),
            page === 6 &&
                react_1.default.createElement(_1.ApplicationSummaryPage, { formLoading: formLoading, toggleFormLoadingState: this.props.toggleFormLoadingState, previous: previousPage, onSubmit: handleFormSubmit, toggleCancelConfirmationModal: this.props.toggleCancelConfirmationModal, toggleCommentModal: this.props.toggleCommentModal, refreshApplication: this.props.refreshApplication, workItemWidget: this.props.workItemWidget, isRealId: isRealId, navToPage: navToPage, onPageJump: onPageJump })));
    }
}
exports.default = RealIdFormPages;
