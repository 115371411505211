"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const valid_ui_components_1 = require("valid-ui-components");
const apifw_1 = require("../../../../requests/apifw");
const PrintTempDoc = (props) => {
    const [generatingDocument, setGeneratingDocument] = (0, react_1.useState)(false);
    const [selectedTemplateId, setSelectedTemplateId] = (0, react_1.useState)('');
    const [tempDocTemplates, setTempDocTemplates] = (0, react_1.useState)([]);
    const openDataUriWindow = (url) => {
        const html = '<html>' +
            '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
            '<body>' +
            '<iframe type="application/pdf" src="' + url + '"></iframe>' +
            '</body></html>';
        const newWindow = window.open('about:blank', 'Zupfnoter');
        newWindow.document.write(html);
        newWindow.document.close();
    };
    (0, react_1.useEffect)(() => {
        (0, apifw_1.getTempDocTemplates)()
            .then((res) => {
            setTempDocTemplates(res.data.data);
        });
    }, []);
    const onTemplateSelect = (selectedTemplate) => setSelectedTemplateId(selectedTemplate.value);
    const generateDocument = () => {
        const templateId = selectedTemplateId;
        setGeneratingDocument(true);
        (0, apifw_1.generateTempDocForCredentialRequest)(props.credentialRequestId, templateId)
            .then((res) => {
            const pdfURI = `data:application/pdf;base64,${res.data}`;
            openDataUriWindow(pdfURI);
            setGeneratingDocument(false);
        })
            .catch((err) => {
            setGeneratingDocument(false);
        });
    };
    const toggle = () => {
        setGeneratingDocument(false);
        props.toggle();
    };
    // Get the template options from the get template endpoint
    const templateSelectOptions = tempDocTemplates
        ? tempDocTemplates.map((template, i) => {
            return { value: template.id, label: template.name };
        })
        : [{ value: 0, label: 'unavailable' }];
    return (react_1.default.createElement(reactstrap_1.Modal, { isOpen: props.isOpen, toggle: toggle },
        react_1.default.createElement(reactstrap_1.ModalHeader, { toggle: toggle }, "Print Temp Doc"),
        react_1.default.createElement(reactstrap_1.ModalBody, null,
            react_1.default.createElement(reactstrap_1.Row, null,
                react_1.default.createElement(reactstrap_1.Col, null,
                    react_1.default.createElement(reactstrap_1.Form, null,
                        react_1.default.createElement(reactstrap_1.FormGroup, null,
                            react_1.default.createElement(reactstrap_1.Label, null, "Template"),
                            react_1.default.createElement(valid_ui_components_1.ValidReactSelect, { className: "col-md-6", options: templateSelectOptions, onChange: onTemplateSelect })))))),
        react_1.default.createElement(reactstrap_1.ModalFooter, null,
            react_1.default.createElement(reactstrap_1.Button, { onClick: toggle }, "Cancel"),
            react_1.default.createElement(reactstrap_1.Button, { color: "primary", className: "float-right", onClick: generateDocument, disabled: generatingDocument }, generatingDocument ? 'Generating...' : 'Generate Document'))));
};
exports.default = PrintTempDoc;
