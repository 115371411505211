"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actionMap = {
    GET_AGENCY_ENROLLMENT_AGENT_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    GET_AGENCY_ENROLLMENT_AGENT_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agent: action.payload });
    },
    GET_AGENCY_ENROLLMENT_AGENT_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_AGENT_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_AGENT_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agent: action.payload });
    },
    CREATE_AGENCY_ENROLLMENT_AGENT_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    UPDATE_AGENCY_ENROLLMENT_AGENT_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    UPDATE_AGENCY_ENROLLMENT_AGENT_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agent: action.payload });
    },
    UPDATE_AGENCY_ENROLLMENT_AGENT_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_PHOTO_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_PHOTO_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        agencyEnrollmentWorkflowState.agent.photos.push(action.payload);
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_PHOTO_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_SIGNATURE_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_SIGNATURE_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        agencyEnrollmentWorkflowState.agent.signatures.push(action.payload);
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_SIGNATURE_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agentPhotoHistory: action.payload });
    },
    GET_AGENCY_ENROLLMENT_AGENT_PHOTOS_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agentSignatureHistory: action.payload });
    },
    GET_AGENCY_ENROLLMENT_AGENT_SIGNATURES_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    CREATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agent: Object.assign(Object.assign({}, agencyEnrollmentWorkflowState.agent), { addresses: [...agencyEnrollmentWorkflowState.agent.addresses, action.payload] }) });
    },
    CREATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    UPDATE_AGENCY_ENROLLMENT_ADDRESS_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    UPDATE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agent: Object.assign(Object.assign({}, agencyEnrollmentWorkflowState.agent), { addresses: agencyEnrollmentWorkflowState.agent.addresses.map((address) => {
                    if (address.id === action.payload.id) {
                        return action.payload;
                    }
                    return address;
                }) }) });
    },
    UPDATE_AGENCY_ENROLLMENT_ADDRESS_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    DELETE_AGENCY_ENROLLMENT_ADDRESS_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    DELETE_AGENCY_ENROLLMENT_ADDRESS_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agent: Object.assign(Object.assign({}, agencyEnrollmentWorkflowState.agent), { addresses: agencyEnrollmentWorkflowState.agent.addresses.filter((address) => {
                    return address.id !== action.payload;
                }) }) });
    },
    DELETE_AGENCY_ENROLLMENT_ADDRESS_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    TEARDOWN_AGENCY_ENROLLMENT: (agencyEnrollmentWorkflowState, action) => {
        return {
            agencyAddresses: agencyEnrollmentWorkflowState.agencyAddresses
        };
    },
    GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_REQUEST: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    },
    GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_SUCCESS: (agencyEnrollmentWorkflowState, action) => {
        return Object.assign(Object.assign({}, agencyEnrollmentWorkflowState), { agencyAddresses: action.payload });
    },
    GET_AGENCY_ENROLLMENT_AGENT_ADDRESSES_FAILURE: (agencyEnrollmentWorkflowState, action) => {
        return agencyEnrollmentWorkflowState;
    }
};
const agencyEnrollmentHandler = (agencyEnrollmentWorkflowState, action) => {
    if (actionMap[action.type]) {
        return actionMap[action.type](agencyEnrollmentWorkflowState, action);
    }
    else {
        return agencyEnrollmentWorkflowState;
    }
};
exports.default = agencyEnrollmentHandler;
