"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardOrderConfigurator = void 0;
const react_1 = __importStar(require("react"));
const react_beautiful_dnd_1 = require("react-beautiful-dnd");
const WizardDraggable_1 = require("./WizardDraggable");
const WizardOrderConfigurator = ({ isOpen, modals, preExistingModalOrder, preExistingModalConfigs, onModalOrderChange, onConfigurablePropsChange }) => {
    const unorderedAvailableModalNames = Object
        .keys(modals)
        .filter(name => !(preExistingModalOrder === null || preExistingModalOrder === void 0 ? void 0 : preExistingModalOrder.includes(name)))
        .sort();
    const orderedAvailableModalNames = (preExistingModalOrder === null || preExistingModalOrder === void 0 ? void 0 : preExistingModalOrder.filter(name => Object.keys(modals).includes(name))) || [];
    const [modalsOrder, setModalsOrder] = (0, react_1.useState)({
        orderedPages: {
            title: `${name} Page Order`,
            list: [...orderedAvailableModalNames]
        },
        availableUnusedPages: {
            title: `Unused ${name} Pages`,
            list: [...unorderedAvailableModalNames]
        }
    });
    const move = (source, destination) => {
        if (!Object.keys(modalsOrder).includes(source.droppableId)
            || !Object.keys(modalsOrder).includes(destination.droppableId)) {
            return;
        }
        const sourceClone = [...modalsOrder[source.droppableId].list];
        const destClone = source.droppableId === destination.droppableId
            ? sourceClone
            : [...modalsOrder[destination.droppableId].list];
        const [removed] = sourceClone.splice(source.index, 1);
        destClone.splice(destination.index, 0, removed);
        const newOrder = {
            [source.droppableId]: {
                title: modalsOrder[source.droppableId].title,
                list: sourceClone
            },
            [destination.droppableId]: {
                title: modalsOrder[destination.droppableId].title,
                list: destClone
            }
        };
        setModalsOrder(Object.assign(Object.assign({}, modalsOrder), newOrder));
        return Object.assign(Object.assign({}, modalsOrder), newOrder);
    };
    const onDragEnd = ({ source, destination }) => {
        if (!destination) {
            return;
        }
        const reorderedItems = move(source, destination);
        onModalOrderChange(reorderedItems.orderedPages.list);
    };
    return react_1.default.createElement("div", { className: "settings-body" }, isOpen ? react_1.default.createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: onDragEnd }, Object.keys(modalsOrder).map(list => react_1.default.createElement(react_beautiful_dnd_1.Droppable, { key: list, droppableId: list }, (provided, snapshot) => react_1.default.createElement("div", { className: "settings-list" },
        react_1.default.createElement("h3", null, modalsOrder[list].title),
        react_1.default.createElement("hr", null),
        react_1.default.createElement("div", Object.assign({ className: `dropbox ${snapshot.isDraggingOver ?
                'dragging' :
                ''}`, ref: provided.innerRef }, provided.droppableProps),
            modalsOrder[list].list.map((item, index) => {
                const configurators = modals[item]
                    .propConfigurators || {};
                return react_1.default.createElement(WizardDraggable_1.WizardDraggable, { key: item, item: item, index: index, modals: modals, configurators: configurators, onConfigurablePropsChange: onConfigurablePropsChange, preExistingModalConfigs: preExistingModalConfigs });
            }),
            provided.placeholder)))))
        : null);
};
exports.WizardOrderConfigurator = WizardOrderConfigurator;
