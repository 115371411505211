"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldDisplayNameMapping = void 0;
exports.fieldDisplayNameMapping = {
    heightfeet: 'Height (ft)',
    birthcountry: 'Birth Country',
    addrstate: 'Address States',
    licenseenhancement: 'License Enhancement',
    vipissuancetype: 'VIP Issuance Type',
    licenseduration: 'License Duration',
    vipagency: 'VIP Agency',
    holds: 'Holds',
    replacementreason: 'Replacement Reason',
    eyecolor: 'Eye Color',
    apocity: 'APO City',
    apostate: 'APO State',
    birthstate: 'Birth State',
    heightinches: 'Height (in)',
    licenseclass: 'License Class',
    suffix: 'Suffix',
    agerange: 'Age Range',
    licensetype: 'License Type',
    servicetype: 'Service Type',
    gender: 'Gender',
    endorsements: 'Endorsements',
    restrictions: 'Restrictions'
};
