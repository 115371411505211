"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressTypeIcons = exports.addressDisplayNameMap = void 0;
exports.addressDisplayNameMap = {
    0: 'Residential',
    1: 'Commercial',
    2: 'Mailing',
    3: 'Special Mailing',
    4: 'POBox'
};
exports.addressTypeIcons = {
    0: 'fas fa-home',
    1: 'fas fa-building',
    2: 'fas fa-envelope',
    3: 'fas fa-star-of-life',
    4: 'fas fa-mailbox'
};
