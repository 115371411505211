"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const redux_1 = require("redux");
const tempDocActions_1 = require("../../../redux/actions/tempDocs/tempDocActions");
const valid_ui_framework_1 = require("valid-ui-framework");
const valid_ui_components_1 = require("valid-ui-components");
const PrintTempdocButton = (props) => {
    const { credentialRequest } = props;
    const [printingTempdoc, setPrintingTempdoc] = (0, react_1.useState)(false);
    const openDataUriWindow = (url) => {
        const html = '<html>' +
            '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;} ' +
            '</style> <body>' +
            '<iframe type="application/pdf" src="' + url + '" id="tempDoc" ></iframe>' +
            '</body></html>';
        const newWindow = window.open('about:blank', 'Temp Doc');
        newWindow.document.write(html);
        newWindow.document.close();
        newWindow.focus();
    };
    const generateTempdoc = () => {
        setPrintingTempdoc(true);
        props.generateTempDocForCredentialRequest(credentialRequest.id)
            .then((res) => {
            const pdfURI = `data:application/pdf;base64,${res.data}`;
            openDataUriWindow(pdfURI);
            setPrintingTempdoc(false);
        })
            .catch((err) => {
            (0, valid_ui_components_1.errorToast)('There was an error generating the temporary document.');
            console.error(err);
            setPrintingTempdoc(false);
        });
    };
    return (react_1.default.createElement(valid_ui_components_1.IconButton, { defaultIconClass: false, className: "btn btn-info", title: "Print Temp Doc", onClick: generateTempdoc, loading: printingTempdoc, spinnerSize: 1 }, "Print Temp Doc"));
};
const mapStateToProps = (state) => {
    return {
        credentialRequest: state.applicationReview.credentialRequest
    };
};
const mapDispatchToProps = (dispatch) => (0, redux_1.bindActionCreators)({
    generateTempDocForCredentialRequest: tempDocActions_1.generateTempDocForCredentialRequest
}, dispatch);
exports.default = (0, valid_ui_framework_1.frameworkConnect)(mapStateToProps, mapDispatchToProps)(PrintTempdocButton);
