"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardPages = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const WizardPages = ({ pages, onCompletion }) => {
    const [renderablePages, setRenderablePages] = (0, react_1.useState)(pages);
    const [selectedModalIndex, setSelectedModalIndex] = (0, react_1.useState)(renderablePages.length > 0 ? 0 : null);
    const [pageStack, setPageStack] = (0, react_1.useState)([]);
    const stepDone = () => {
        const previousPage = pageStack.slice(-1).pop();
        let pageRenderedDiff = 0;
        for (let i = 0; i < pages.length; i++) {
            if (!renderablePages.map(page => page.id).includes(pages[i].id)
                && i <= selectedModalIndex) {
                pageRenderedDiff++;
            }
        }
        if (!previousPage
            && selectedModalIndex + pageRenderedDiff + 1 >= pages.length) {
            setSelectedModalIndex(renderablePages.length - 1);
            onCompletion();
        }
        else {
            setPageStack(pageStack.slice(0, pageStack.length - 1));
            setSelectedModalIndex(previousPage || (selectedModalIndex + pageRenderedDiff + 1));
            setRenderablePages([...pages]);
        }
        return null;
    };
    const jumpTo = (pageId) => {
        const jumpPageIndex = renderablePages.findIndex(page => page.id === pageId);
        if (jumpPageIndex > -1 && jumpPageIndex < renderablePages.length) {
            setPageStack(pageStack.concat(selectedModalIndex));
            setSelectedModalIndex(jumpPageIndex);
        }
    };
    const modalsAvailable = renderablePages
        .map((modalName, i) => {
        var _a;
        const Comp = renderablePages[i];
        const incrementModalIndex = () => {
            setPageStack([]);
            setSelectedModalIndex(i + 1);
        };
        const decrementModalIndex = () => {
            setPageStack([]);
            setSelectedModalIndex(i - 1);
        };
        return react_1.default.createElement(Comp.Component, Object.assign({ key: modalName, toggle: onCompletion, done: stepDone, jumpTo: jumpTo }, ((_a = Comp.configurableProps) !== null && _a !== void 0 ? _a : {}), Comp.props),
            react_1.default.createElement("div", { className: "wizard-controls" },
                react_1.default.createElement(reactstrap_1.Button, { disabled: i <= 0, onClick: decrementModalIndex }, "Previous"),
                react_1.default.createElement(reactstrap_1.Button, { color: "primary", disabled: i >= renderablePages.length - 1, onClick: incrementModalIndex }, "Next")),
            Comp.additionalFormElements);
    });
    const pageDecider = renderablePages.map((page, i) => {
        const doneCalledImmediately = () => {
            if (renderablePages.find((rPage) => rPage.id === page.id)) {
                if (i <= selectedModalIndex) {
                    setSelectedModalIndex(smi => {
                        return smi - 1;
                    });
                }
                setRenderablePages(rPages => {
                    return rPages.filter(pageToRemove => {
                        return pageToRemove.id !== page.id;
                    });
                });
            }
        };
        return react_1.default.createElement(page.Component, Object.assign({ done: doneCalledImmediately, key: `hidden-${new Date().getTime()}-${i}` }, page.props, { HWSReady: false }));
    });
    const currentModal = selectedModalIndex !== null ?
        modalsAvailable[selectedModalIndex] :
        null;
    return react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { style: { display: 'none' } }, pageDecider),
        currentModal);
};
exports.WizardPages = WizardPages;
