"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actionMap = {
    GET_CM_IDENTITY_REQUEST: (cardManagementWorkflowState, action) => {
        return cardManagementWorkflowState;
    },
    GET_CM_IDENTITY_SUCCESS: (cardManagementWorkflowState, action) => {
        return Object.assign(Object.assign({}, cardManagementWorkflowState), { identity: action.payload });
    },
    GET_CM_IDENTITY_FAILURE: (cardManagementWorkflowState, action) => {
        return cardManagementWorkflowState;
    },
    TEARDOWN_CM_WORKFLOW: (cardManagementWorkflowState, action) => {
        return {
            identity: {}
        };
    }
};
const mergeIdentityHandler = (cardManagementWorkflowState, action) => {
    if (actionMap[action.type]) {
        return actionMap[action.type](cardManagementWorkflowState, action);
    }
    else {
        return cardManagementWorkflowState;
    }
};
exports.default = mergeIdentityHandler;
