"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actionMap = {
    GET_LICENSE_ISSUANCE_PERSON_REQUEST: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_PERSON_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: action.payload });
    },
    GET_LICENSE_ISSUANCE_PERSON_FAILURE: (state, action) => {
        return state;
    },
    GET_WORK_ITEMS_REQUEST: (state, action) => {
        return Object.assign(Object.assign({}, state), { workItems: [] });
    },
    GET_WORK_ITEMS_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { workItems: action.payload });
    },
    GET_WORK_ITEMS_FAILURE: (state, action) => {
        return state;
    },
    GET_WORK_ITEM_TYPES_REQUEST: (state, action) => {
        return state;
    },
    GET_WORK_ITEM_TYPES_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { workItemTypes: action.payload.data });
    },
    GET_WORK_ITEM_TYPES_FAILURE: (state, action) => {
        return state;
    },
    GET_WORK_ITEM__REASONS_REQUEST: (state, action) => {
        return state;
    },
    GET_WORK_ITEM__REASONS_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { workItemTypeReasons: action.payload });
    },
    GET_WORK_ITEM_TYPE_REASONS_FAILURE: (state, action) => {
        return state;
    },
    VERIFY_DOCUMENT_REQUEST: (state, action) => {
        return state;
    },
    VERIFY_DOCUMENT_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { documentVerification: action.payload });
    },
    VERIFY_DOCUMENT_FAILURE: (state, action) => {
        return state;
    },
    CREATE_WORK_ITEM_FAILURE: (state, action) => {
        return state;
    },
    CREATE_WORK_ITEM_REQUEST: (state, action) => {
        return state;
    },
    CREATE_WORK_ITEM_SUCCESS: (state, action) => {
        return state;
    },
    CREATE_DOCUMENT_FAILURE: (state, action) => {
        return state;
    },
    CREATE_DOCUMENT_REQUEST: (state, action) => {
        return state;
    },
    CREATE_DOCUMENT_SUCCESS: (state, action) => {
        const documentsState = state.documents || [];
        documentsState.push(action.payload);
        state.documents = documentsState;
        return state;
    },
    OVERRIDE_DOCUMENT_VERIFICATION_REQUEST: (state, action) => {
        return state;
    },
    OVERRIDE_DOCUMENT_VERIFICATION_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { documentVerification: action.payload });
    },
    OVERRIDE_DOCUMENT_VERIFICATION_FAILURE: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_DOCUMENT_REQUEST: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_DOCUMENT_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { documentVerification: action.payload });
    },
    CREATE_LICENSE_ISSUANCE_DOCUMENT_FAILURE: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_PERSON_FINALIZATION_REQUEST: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_PERSON_FINALIZATION_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { personFinalization: action.payload });
    },
    GET_LICENSE_ISSUANCE_PERSON_FINALIZATION_FAILURE: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_PERSON_REQUEST: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_PERSON_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: action.payload });
    },
    CREATE_LICENSE_ISSUANCE_PERSON_FAILURE: (state, action) => {
        return state;
    },
    UPDATE_LICENSE_ISSUANCE_PERSON_REQUEST: (state, action) => {
        return state;
    },
    UPDATE_LICENSE_ISSUANCE_PERSON_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: action.payload });
    },
    UPDATE_LICENSE_ISSUANCE_PERSON_FAILURE: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_CR_REQUEST: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_CR_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: action.payload });
    },
    GET_LICENSE_ISSUANCE_CR_FAILURE: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_CR_FINALIZATION_REQUEST: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_CR_FINALIZATION_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialFinalization: action.payload });
    },
    GET_LICENSE_ISSUANCE_CR_FINALIZATION_FAILURE: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_CR_REQUEST: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_CR_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: action.payload });
    },
    CREATE_LICENSE_ISSUANCE_CR_FAILURE: (state, action) => {
        return state;
    },
    UPDATE_LICENSE_ISSUANCE_CR_REQUEST: (state, action) => {
        return state;
    },
    UPDATE_LICENSE_ISSUANCE_CR_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: action.payload });
    },
    UPDATE_LICENSE_ISSUANCE_CR_FAILURE: (state, action) => {
        return state;
    },
    TEARDOWN_LICENSE_ISSUANCE_WORKFLOW: (state, action) => {
        return {
            person: {},
            credentialRequest: {},
            personFinalization: {},
            credentialFinalization: {},
            documentVerification: {},
            activePhoto: {},
            activeSignature: {},
            workItems: [],
            workItemTypes: [...state.workItemTypes]
        };
    },
    CREATE_ADDRESS_FOR_LIC_ISSUE_PERSON_REQUEST: (state, action) => {
        return state;
    },
    CREATE_ADDRESS_FOR_LIC_ISSUE_PERSON_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: Object.assign(Object.assign({}, state.person), { addresses: [...state.person.addresses, action.payload] }) });
    },
    CREATE_ADDRESS_FOR_LIC_ISSUE_PERSON_FAILURE: (state, action) => {
        return state;
    },
    UPDATE_ADDRESS_FOR_LIC_ISSUE_PERSON_REQUEST: (state, action) => {
        return state;
    },
    UPDATE_ADDRESS_FOR_LIC_ISSUE_PERSON_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: Object.assign(Object.assign({}, state.person), { addresses: state.person.addresses.map((address) => {
                    if (action.payload.id === address.id) {
                        return action.payload;
                    }
                    return address;
                }) }) });
    },
    UPDATE_ADDRESS_FOR_LIC_ISSUE_PERSON_FAILURE: (state, action) => {
        return state;
    },
    DELETE_ADDRESS_FROM_LIC_ISSUE_PERSON_REQUEST: (state, action) => {
        return state;
    },
    DELETE_ADDRESS_FROM_LIC_ISSUE_PERSON_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: Object.assign(Object.assign({}, state.person), { addresses: state.person.addresses.filter((address) => {
                    return action.payload !== address.id;
                }) }) });
    },
    SET_PRINT_ADDRESS_ON_LIC_ISSUE_PERSON_REQUEST: (state, action) => {
        return state;
    },
    SET_PRINT_ADDRESS_ON_LIC_ISSUE_PERSON_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: Object.assign(Object.assign({}, state.person), { cardAddressId: action.payload.cardAddressId }) });
    },
    SET_PRINT_ADDRESS_ON_LIC_ISSUE_PERSON_FAILURE: (state, action) => {
        return state;
    },
    CREATE_ADDRESS_FOR_LIC_ISSUE_CR_REQUEST: (state, action) => {
        return state;
    },
    CREATE_ADDRESS_FOR_LIC_ISSUE_CR_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: Object.assign(Object.assign({}, state.credentialRequest), { addresses: [...state.credentialRequest.addresses, action.payload] }) });
    },
    CREATE_ADDRESS_FOR_LIC_ISSUE_CR_FAILURE: (state, action) => {
        return state;
    },
    UPDATE_ADDRESS_FOR_LIC_ISSUE_CR_REQUEST: (state, action) => {
        return state;
    },
    UPDATE_ADDRESS_FOR_LIC_ISSUE_CR_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: Object.assign(Object.assign({}, state.credentialRequest), { addresses: state.credentialRequest.addresses.map((address) => {
                    if (action.payload.id === address.id) {
                        return action.payload;
                    }
                    return address;
                }) }) });
    },
    UPDATE_ADDRESS_FOR_LIC_ISSUE_CR_FAILURE: (state, action) => {
        return state;
    },
    DELETE_ADDRESS_FROM_LIC_ISSUE_CR_REQUEST: (state, action) => {
        return state;
    },
    DELETE_ADDRESS_FROM_LIC_ISSUE_CR_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: Object.assign(Object.assign({}, state.credentialRequest), { addresses: state.credentialRequest.addresses.filter((address) => {
                    if (action.payload !== address.id) {
                        return address;
                    }
                }) }) });
    },
    DELETE_ADDRESS_FROM_LIC_ISSUE_PERSON_FAILURE: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_PHOTO_REQUEST: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_PHOTO_SUCCESS: (state, action) => {
        const newPhotoState = [];
        newPhotoState.push(action.payload);
        state.person.photos = newPhotoState;
        return state;
    },
    CREATE_LICENSE_ISSUANCE_PHOTO_FAILURE: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_SIGNATURE_REQUEST: (state, action) => {
        return state;
    },
    CREATE_LICENSE_ISSUANCE_SIGNATURE_SUCCESS: (state, action) => {
        state.person.signatures.push(action.payload);
        return state;
    },
    CREATE_LICENSE_ISSUANCE_SIGNATURE_FAILURE: (state, action) => {
        return state;
    },
    UPDATE_LICENSE_ISSUANCE_PHOTO_REQUEST: (state, action) => {
        return state;
    },
    UPDATE_LICENSE_ISSUANCE_PHOTO_SUCCESS: (state, action) => {
        const newPhotoState = [];
        newPhotoState.push(action.payload);
        state.person.photos = newPhotoState;
        return state;
    },
    UPDATE_LICENSE_ISSUANCE_PHOTO_FAILURE: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_ACTIVE_PHOTO_REQUEST: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_ACTIVE_PHOTO_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { activePhoto: action.payload });
    },
    GET_LICENSE_ISSUANCE_ACTIVE_PHOTO_FAILURE: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_ACTIVE_SIGNATURE_REQUEST: (state, action) => {
        return state;
    },
    GET_LICENSE_ISSUANCE_ACTIVE_SIGNATURE_SUCCESS: (state, action) => {
        return Object.assign(Object.assign({}, state), { activeSignature: action.payload });
    },
    GET_LICENSE_ISSUANCE_ACTIVE_SIGNATURE_FAILURE: (state, action) => {
        return state;
    }
};
const licenseIssuanceHandler = (state, action) => {
    if (actionMap[action.type]) {
        return actionMap[action.type](state, action);
    }
    else {
        return state;
    }
};
exports.default = licenseIssuanceHandler;
