"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aboutConfigs = void 0;
const aboutConfigs = {
    appName: 'Demo UI',
    aboutText: {
        title: 'About Demo',
        description: 'This is a demonstration '
            + 'credentialing application in the Valid Identity Cloud (VIC). '
            + 'This application collects customer data, images, and signatures '
            + 'in order to create and deliver a highly secure license or identification '
            + 'card. Built-in image investigation tools and biometric matching evaluate '
            + 'photos for identity verification.'
    }
};
exports.aboutConfigs = aboutConfigs;
