"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const valid_ui_components_1 = require("valid-ui-components");
const redux_1 = require("redux");
const tempDocActions_1 = require("../../redux/actions/tempDocs/tempDocActions");
const valid_ui_framework_1 = require("valid-ui-framework");
class PrintTempDoc extends react_1.Component {
    constructor() {
        super(...arguments);
        this.state = {
            generatingDocument: false,
            documentGenerated: false,
            selectedTemplateId: ''
        };
        this.openDataUriWindow = (url) => {
            const html = '<html>' +
                '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                '<body>' +
                '<iframe type="application/pdf" src="' + url + '"></iframe>' +
                '</body></html>';
            const newWindow = window.open('about:blank', 'Zupfnoter');
            newWindow.document.write(html);
            newWindow.document.close();
        };
        this.componentDidMount = () => {
            this.props.getTempDocTemplates(100);
        };
        this.onTemplateSelect = (selectedTemplate) => this.setState({ selectedTemplateId: selectedTemplate.value });
        this.generateDocument = () => {
            const templateId = this.state.selectedTemplateId;
            this.setState({ generatingDocument: true });
            this.props.generateTempDocForCredentialRequest(this.props.credentialRequestId, templateId)
                .then((res) => {
                const pdfURI = `data:application/pdf;base64,${res.res}`;
                this.openDataUriWindow(pdfURI);
                this.setState({ generatingDocument: false });
            })
                .catch((err) => {
                this.setState({ generatingDocument: false });
            });
        };
        this.toggle = () => {
            this.setState({
                generatingDocument: false,
                documentGenerated: false
            });
            this.props.toggle();
        };
    }
    render() {
        const { isOpen } = this.props;
        // Get the template options from the get template endpoint
        const templateSelectOptions = this.props.templates.map((template, i) => {
            return { value: template.id, label: template.name };
        });
        return (react_1.default.createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: this.toggle },
            react_1.default.createElement(reactstrap_1.ModalHeader, { toggle: this.toggle }, "Print Temp Doc"),
            react_1.default.createElement(reactstrap_1.ModalBody, null,
                react_1.default.createElement(reactstrap_1.Row, null,
                    react_1.default.createElement(reactstrap_1.Col, null,
                        react_1.default.createElement(reactstrap_1.Form, null,
                            react_1.default.createElement(reactstrap_1.FormGroup, null,
                                react_1.default.createElement(reactstrap_1.Label, null, "Template"),
                                react_1.default.createElement(valid_ui_components_1.ValidReactSelect, { className: "col-md-6", options: templateSelectOptions, onChange: this.onTemplateSelect })))))),
            react_1.default.createElement(reactstrap_1.ModalFooter, null,
                react_1.default.createElement(reactstrap_1.Button, { onClick: this.toggle }, "Cancel"),
                react_1.default.createElement(reactstrap_1.Button, { color: "primary", className: "float-right", onClick: this.generateDocument, disabled: this.state.generatingDocument }, this.state.generatingDocument ? 'Generating...' : 'Generate Document'))));
    }
}
const mapStateToProps = (state) => {
    return {
        templates: state.tempdocs.tempdocList.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return (0, redux_1.bindActionCreators)({ getTempDocTemplates: tempDocActions_1.getTempDocTemplates, generateTempDocForCredentialRequest: tempDocActions_1.generateTempDocForCredentialRequest }, dispatch);
};
exports.default = (0, valid_ui_framework_1.frameworkConnect)(mapStateToProps, mapDispatchToProps)(PrintTempDoc);
