"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actionMap = {
    GET_IDENTITY_ONE_REQUEST: (mergeIdentityWorkflowState, action) => {
        return mergeIdentityWorkflowState;
    },
    GET_IDENTITY_ONE_SUCCESS: (mergeIdentityWorkflowState, action) => {
        return Object.assign(Object.assign({}, mergeIdentityWorkflowState), { identityOne: action.payload });
    },
    GET_IDENTITY_ONE_FAILURE: (mergeIdentityWorkflowState, action) => {
        return mergeIdentityWorkflowState;
    },
    GET_IDENTITY_TWO_REQUEST: (mergeIdentityWorkflowState, action) => {
        return mergeIdentityWorkflowState;
    },
    GET_IDENTITY_TWO_SUCCESS: (mergeIdentityWorkflowState, action) => {
        return Object.assign(Object.assign({}, mergeIdentityWorkflowState), { identityTwo: action.payload });
    },
    GET_IDENTITY_TWO_FAILURE: (mergeIdentityWorkflowState, action) => {
        return mergeIdentityWorkflowState;
    },
    MERGE_IDENTITIES_REQUEST: (mergeIdentityWorkflowState, action) => {
        return mergeIdentityWorkflowState;
    },
    MERGE_IDENTITIES_SUCCESS: (mergeIdentityWorkflowState, action) => {
        const winnerId = action.payload.id;
        const identityOne = mergeIdentityWorkflowState.identityOne;
        const identityTwo = mergeIdentityWorkflowState.identityTwo;
        if (identityOne.id === winnerId) {
            return Object.assign(Object.assign({}, mergeIdentityWorkflowState), { identityOne: action.payload, identityTwo: {} });
        }
        else if (identityTwo.id === winnerId) {
            return Object.assign(Object.assign({}, mergeIdentityWorkflowState), { identityOne: {}, identityTwo: action.payload });
        }
        else {
            return mergeIdentityWorkflowState;
        }
    },
    MERGE_IDENTITIES_FAILURE: (mergeIdentityWorkflowState, action) => {
        return mergeIdentityWorkflowState;
    },
    TEARDOWN_MERGE_IDENTITY_WORKFLOW: (mergeIdentityWorkflowState, action) => {
        return {
            identityOne: {},
            identityTwo: {}
        };
    }
};
const mergeIdentityHandler = (mergeIdentityWorkflowState, action) => {
    if (actionMap[action.type]) {
        return actionMap[action.type](mergeIdentityWorkflowState, action);
    }
    else {
        return mergeIdentityWorkflowState;
    }
};
exports.default = mergeIdentityHandler;
