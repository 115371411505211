"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extraAppReviewActionsMap = void 0;
const extraAppReviewActionsMap = {
    GET_APP_REVIEW_WORK_ITEM_TYPES_SUCCESS: (appReviewWorkflowState, action) => {
        return Object.assign(Object.assign({}, appReviewWorkflowState), { workItemTypes: action.payload.data });
    },
    UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_REQUEST: (appReviewWorkflowState, action) => {
        return appReviewWorkflowState;
    },
    UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_SUCCESS: (appReviewWorkflowState, action) => {
        return Object.assign(Object.assign({}, appReviewWorkflowState), { credentialRequest: action.payload });
    },
    UPDATE_APP_REVIEW_CR_WITH_TEMPDOC_FAILURE: (appReviewWorkflowState, action) => {
        return appReviewWorkflowState;
    }
};
exports.extraAppReviewActionsMap = extraAppReviewActionsMap;
