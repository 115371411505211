"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actionMap = {
    SET_PHOTO: (state, action) => {
        return Object.assign(Object.assign({}, state), { activePhoto: action.payload });
    },
    SET_SIG: (state, action) => {
        return Object.assign(Object.assign({}, state), { activeSignature: action.payload });
    },
    SET_PERSON: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: action.payload });
    },
    SET_CR: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: action.payload });
    },
    SET_LOADING: (state, action) => {
        return Object.assign(Object.assign({}, state), { isLoading: action.payload });
    },
    SET_STORE: (state, action) => {
        return Object.assign(Object.assign({}, state), action.payload);
    },
    SET_HWS_READY: (state, action) => {
        return Object.assign(Object.assign({}, state), { hwsReady: action.payload });
    },
    TOGGLE_LOADING: (state, action) => {
        return Object.assign(Object.assign({}, state), { isLoading: !state.isLoading });
    },
    SET_WORK_ITEM_TYPES: (state, action) => {
        return Object.assign(Object.assign({}, state), { workItemTypes: action.payload });
    },
    SET_PERSON_DOCS: (state, action) => {
        return Object.assign(Object.assign({}, state), { person: Object.assign(Object.assign({}, state.person), { documents: action.payload }) });
    },
    SET_VALID_WITHOUT_SIGNATURE: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: Object.assign(Object.assign({}, state.credentialRequest), { fields: Object.assign(Object.assign({}, state.credentialRequest.fields), { validwithoutsignature: action.payload }) }) });
    },
    SET_VALID_WITHOUT_PHOTO: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequest: Object.assign(Object.assign({}, state.credentialRequest), { fields: Object.assign(Object.assign({}, state.credentialRequest.fields), { validwithoutphoto: action.payload }) }) });
    },
    SET_DOCUMENT_TYPES: (state, action) => {
        return Object.assign(Object.assign({}, state), { documentTypes: action.payload });
    },
    SET_PERSON_FINALIZATIONS: (state, action) => {
        return Object.assign(Object.assign({}, state), { personFinalization: Object.assign({}, action.payload) });
    },
    SET_CR_FINALIZATIONS: (state, action) => {
        return Object.assign(Object.assign({}, state), { credentialRequestFinalization: action.payload });
    },
    SET_RELEASED_TO_PRINT: (state, action) => {
        return Object.assign(Object.assign({}, state), { releasedToPrint: action.payload });
    }
};
const Reducer = (state, action) => {
    if (action && action.type && actionMap[action.type]) {
        return actionMap[action.type](state, action);
    }
    else {
        return state;
    }
};
exports.default = Reducer;
