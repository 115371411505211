"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const CaptureOnlyContext_1 = require("./CaptureOnlyContext");
const workItemRequests_1 = require("../../../requests/apifw/workItemRequests");
const apifw_1 = require("../../../requests/apifw");
const useCaptureOnlyStore = () => {
    var _a, _b, _c, _d;
    const [state, dispatch] = (0, react_1.useContext)(CaptureOnlyContext_1.CaptureOnlyContext);
    const store = Object.assign({}, state);
    const activePhoto = Object.assign({}, state.activePhoto);
    const activeSignature = Object.assign({}, state.activeSignature);
    const person = Object.assign({}, state.person);
    const personFinalization = Object.assign({}, state.personFinalization);
    const credentialRequest = Object.assign({}, state.credentialRequest);
    const credentialRequestFinalization = state.credentialRequestFinalization;
    const validWithoutPhoto = ((_b = (_a = state === null || state === void 0 ? void 0 : state.credentialRequest) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.validwithoutphoto) || false;
    const validWithoutSignature = ((_d = (_c = state === null || state === void 0 ? void 0 : state.credentialRequest) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d.validwithoutsignature) || false;
    const isLoading = state.isLoading;
    const hwsReady = state.hwsReady;
    const workItemTypes = state.workItemTypes;
    const workItems = state.workItems;
    const documentTypes = [...state.documentTypes];
    const releasedToPrint = state.releasedToPrint;
    const setPhoto = (0, react_1.useCallback)((photo) => {
        dispatch({ type: 'SET_PHOTO', payload: photo });
    }, [dispatch]);
    const setSig = (0, react_1.useCallback)((sig) => {
        dispatch({ type: 'SET_SIG', payload: sig });
    }, [dispatch]);
    const setPerson = (0, react_1.useCallback)((person) => {
        dispatch({ type: 'SET_PERSON', payload: person });
    }, [dispatch]);
    const setCR = (0, react_1.useCallback)((cr) => {
        dispatch({ type: 'SET_CR', payload: cr });
    }, [dispatch]);
    const setLoading = (0, react_1.useCallback)((loading) => {
        dispatch({ type: 'SET_LOADING', payload: loading });
    }, [dispatch]);
    const setWorkItemTypes = (0, react_1.useCallback)((workItemTypes) => {
        dispatch({ type: 'SET_WORK_ITEM_TYPES', payload: workItemTypes });
    }, [dispatch]);
    const setWorkItems = (0, react_1.useCallback)((workItems) => {
        dispatch({ type: 'SET_WORK_ITEMS', payload: workItems });
    }, [dispatch]);
    const setHWSReady = (0, react_1.useCallback)((ready) => {
        dispatch({ type: 'SET_HWS_READY', payload: ready });
    }, [dispatch]);
    const setStore = (0, react_1.useCallback)((store) => {
        dispatch({ type: 'SET_STORE', payload: store });
    }, [dispatch]);
    const toggleLoading = (0, react_1.useCallback)(() => {
        dispatch({ type: 'TOGGLE_LOADING', payload: undefined });
    }, [dispatch]);
    const setPersonDocs = (0, react_1.useCallback)((docs) => {
        dispatch({ type: 'SET_PERSON_DOCS', payload: docs });
    }, [dispatch]);
    const setValidWithoutPhoto = (0, react_1.useCallback)((setValidWithoutPhoto) => {
        dispatch({ type: 'SET_VALID_WITHOUT_PHOTO', payload: setValidWithoutPhoto });
    }, [dispatch]);
    const setValidWithoutSignature = (0, react_1.useCallback)((setValidWithoutSignature) => {
        dispatch({ type: 'SET_VALID_WITHOUT_SIGNATURE', payload: setValidWithoutSignature });
    }, [dispatch]);
    const setDocTypes = (0, react_1.useCallback)((docs) => {
        dispatch({ type: 'SET_DOCUMENT_TYPES', payload: docs });
    }, [dispatch]);
    const setPersonFinalization = (0, react_1.useCallback)((personFinalization) => {
        dispatch({ type: 'SET_PERSON_FINALIZATIONS', payload: personFinalization });
    }, [dispatch]);
    const setCRFinalization = (0, react_1.useCallback)((credentialRequestFinalization) => {
        dispatch({ type: 'SET_CR_FINALIZATIONS', payload: credentialRequestFinalization });
    }, [dispatch]);
    const setReleasedToPrint = (0, react_1.useCallback)((releasedToPrint) => {
        dispatch({ type: 'SET_RELEASED_TO_PRINT', payload: releasedToPrint });
    }, [dispatch]);
    const refreshFinalizations = () => {
        Promise.all([
            (0, apifw_1.getPersonFinalization)(person.id),
            (0, apifw_1.getCredentialRequestFinalization)(credentialRequest.id)
        ])
            .then(([personFinalization, credentialRequestFinalization]) => {
            setPersonFinalization(personFinalization.data);
            setCRFinalization(credentialRequestFinalization.data);
            return { cr: credentialRequestFinalization.data, person: personFinalization.data };
        });
    };
    (0, react_1.useEffect)(() => {
        if (workItemTypes.length < 1) {
            (0, workItemRequests_1.getWorkItemTypes)().then((res) => setWorkItemTypes(res.data.data));
        }
    }, [setWorkItemTypes, workItemTypes.length]);
    return {
        store,
        activePhoto,
        activeSignature,
        credentialRequest,
        credentialRequestFinalization,
        documentTypes,
        hwsReady,
        isLoading,
        person,
        personFinalization,
        releasedToPrint,
        validWithoutPhoto,
        validWithoutSignature,
        workItemTypes,
        workItems,
        setPhoto,
        setSig,
        setPerson,
        setCR,
        setLoading,
        setHWSReady,
        setStore,
        toggleLoading,
        setWorkItemTypes,
        setWorkItems,
        setPersonDocs,
        setDocTypes,
        setValidWithoutPhoto,
        setValidWithoutSignature,
        setPersonFinalization,
        setCRFinalization,
        setReleasedToPrint,
        refreshFinalizations
    };
};
exports.default = useCaptureOnlyStore;
