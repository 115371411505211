"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TEMPDOC_RECORDS_FOR_CR_FAILURE = exports.UPDATE_TEMPDOC_RECORDS_FOR_CR_SUCCESS = exports.UPDATE_TEMPDOC_RECORDS_FOR_CR_REQUEST = exports.EMAIL_TEMPDOC_FOR_CR_FAILURE = exports.EMAIL_TEMPDOC_FOR_CR_SUCCESS = exports.EMAIL_TEMPDOC_FOR_CR_REQUEST = exports.GENERATE_TEMPDOC_FOR_CR_FAILURE = exports.GENERATE_TEMPDOC_FOR_CR_SUCCESS = exports.GENERATE_TEMPDOC_FOR_CR_REQUEST = exports.GET_TEMPDOC_TEMPLATES_FAILURE = exports.GET_TEMPDOC_TEMPLATES_SUCCESS = exports.GET_TEMPDOC_TEMPLATES_REQUEST = void 0;
exports.GET_TEMPDOC_TEMPLATES_REQUEST = 'GET_TEMPDOC_TEMPLATES_REQUEST';
exports.GET_TEMPDOC_TEMPLATES_SUCCESS = 'GET_TEMPDOC_TEMPLATES_SUCCESS';
exports.GET_TEMPDOC_TEMPLATES_FAILURE = 'GET_TEMPDOC_TEMPLATES_FAILURE';
exports.GENERATE_TEMPDOC_FOR_CR_REQUEST = 'GENERATE_TEMPDOC_FOR_CR_REQUEST';
exports.GENERATE_TEMPDOC_FOR_CR_SUCCESS = 'GENERATE_TEMPDOC_FOR_CR_SUCCESS';
exports.GENERATE_TEMPDOC_FOR_CR_FAILURE = 'GENERATE_TEMPDOC_FOR_CR_FAILURE';
exports.EMAIL_TEMPDOC_FOR_CR_REQUEST = 'EMAIL_TEMPDOC_FOR_CR_REQUEST';
exports.EMAIL_TEMPDOC_FOR_CR_SUCCESS = 'EMAIL_TEMPDOC_FOR_CR_SUCCESS';
exports.EMAIL_TEMPDOC_FOR_CR_FAILURE = 'EMAIL_TEMPDOC_FOR_CR_FAILURE';
exports.UPDATE_TEMPDOC_RECORDS_FOR_CR_REQUEST = 'UPDATE_TEMPDOC_RECORDS_FOR_CR_REQUEST';
exports.UPDATE_TEMPDOC_RECORDS_FOR_CR_SUCCESS = 'UPDATE_TEMPDOC_RECORDS_FOR_CR_SUCCESS';
exports.UPDATE_TEMPDOC_RECORDS_FOR_CR_FAILURE = 'UPDATE_TEMPDOC_RECORDS_FOR_CR_FAILURE';
