"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bioEnrollmentFailureNotOverrideCheck = exports.oneToOneFailureNotOverrideCheck = exports.bioQualityFailureNotOverrideCheck = exports.icaoFailureNotOverrideCheck = exports.allowSubmissionICAOChecks = exports.checkVWOP = exports.checkPhotoNoVWOP = void 0;
const checkPhotoNoVWOP = (person, credentialRequest) => {
    var _a, _b;
    return ((_a = person === null || person === void 0 ? void 0 : person.photos) === null || _a === void 0 ? void 0 : _a.length)
        && !((_b = credentialRequest === null || credentialRequest === void 0 ? void 0 : credentialRequest.fields) === null || _b === void 0 ? void 0 : _b.validwithoutphoto);
};
exports.checkPhotoNoVWOP = checkPhotoNoVWOP;
const checkVWOP = (credentialRequest) => {
    return credentialRequest
        && credentialRequest.fields
        && credentialRequest.fields.validwithoutphoto;
};
exports.checkVWOP = checkVWOP;
const allowSubmissionICAOChecks = (person, credentialRequest) => {
    if ((0, exports.checkVWOP)(credentialRequest)) {
        return true;
    }
    else if ((0, exports.checkPhotoNoVWOP)(person, credentialRequest)) {
        return !(0, exports.icaoFailureNotOverrideCheck)(person.photos[0]);
    }
    else {
        return false;
    }
};
exports.allowSubmissionICAOChecks = allowSubmissionICAOChecks;
const icaoFailureNotOverrideCheck = (photo, shouldPerformClientSideQC) => {
    // bug https://validusa.atlassian.net/browse/APIFW-721
    if (photo && photo.icaoVerified === false && photo.icaoOverride !== true) {
        return true;
    }
    else if (photo && shouldPerformClientSideQC && photo.icaoOverride !== true) {
        return true;
    }
    return false;
};
exports.icaoFailureNotOverrideCheck = icaoFailureNotOverrideCheck;
const bioQualityFailureNotOverrideCheck = (photo) => {
    if (photo && !photo.biometricQualityCheckPassed && !photo.biometricQualityCheckOverride) {
        return true;
    }
    return false;
};
exports.bioQualityFailureNotOverrideCheck = bioQualityFailureNotOverrideCheck;
const oneToOneFailureNotOverrideCheck = (photo) => {
    if (photo && !photo.oneToOneVerified && !photo.oneToOneOverride) {
        return true;
    }
    return false;
};
exports.oneToOneFailureNotOverrideCheck = oneToOneFailureNotOverrideCheck;
const bioEnrollmentFailureNotOverrideCheck = (photo) => {
    if (photo && !photo.biometricEnrolled && !photo.biometricEnrollmentSkipped) {
        return true;
    }
    return false;
};
exports.bioEnrollmentFailureNotOverrideCheck = bioEnrollmentFailureNotOverrideCheck;
